import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useMyInfo } from 'hooks';
import { isDetailsFor } from 'utils/utils';
import Paths from '../commons/paths';

const useVideoDetail = (videoId: string) => {
  const { info } = useMyInfo();

  const isVideoDetail = useMemo(
    () =>
      isDetailsFor([
        Paths.getPath('videoList').replace('/list', ''),
        Paths.getPath('newsList').replace('/list', ''),
        Paths.getPath('recommendList').replace('/list', ''),
        Paths.getPath('ESGList').replace('/list', ''),
        'group',
      ]),
    [location.pathname]
  );

  // 재생목록에서 영상을 선택했을때 새로 영상 상세를 조회해오기 위해서 존재한다.
  const [id, setId] = useState(videoId);
  const [detailDesc, setDetailDesc] = useState<string>('');
  const [videoElements, setVideoElements] = useState<Element[]>();

  useEffect(() => {
    // console.log('id', id);
  }, [id]);

  useEffect(() => {
    setId(videoId);
  }, [videoId]);

  const { data: videoDetailData, mutate } = useSWR<MediaDetailResDto>(
    info && id && isVideoDetail ? [`${endpoints.media.media}/${id}`, id] : null,
    url =>
      fetcher.media.getMediaDetail(url).then(res => {
        // console.log('use video detail hook', videoId, id, res);
        return res;
      })
  );

  // playlist는 재생 목록의 id로만 접근이 가능하기 때문에 location의 id로 조회한다.
  const { data: playlistData, mutate: mutatePlaylist } = useSWR<any>(
    info && videoId && isVideoDetail ? [`${endpoints.media.playlist}/${videoId}`, videoId] : null,
    url =>
      fetcher.media.getMediaPlaylist(url).then(res => {
        return res;
      })
  );

  const { data: relatedData, mutate: mutateRelated } = useSWR<any>(
    info && id && isVideoDetail
      ? [`${endpoints.media.related}/${id}?playlistId=${videoId !== id ? videoId : ''}`, id]
      : null,
    url => fetcher.media.getRelatedMedia(url).then(res => res)
  );

  // As-Is 미디어 콘텐츠 script내 video 처리
  useEffect(() => {
    if (videoDetailData?.editorContents) {
      const container = document.createElement('div');
      container.innerHTML = videoDetailData?.editorContents as string;

      const videoEls = Array.from(container.querySelectorAll('.video-js'));

      videoEls.forEach((el, index) => {
        const parentEl = el.parentNode;
        const videoContainer = document.createElement('div');
        videoContainer.id = `video-container-${index}`;

        if (parentEl) {
          parentEl.replaceChild(videoContainer, el as Node);
        }
      });

      setVideoElements(videoEls);
      setDetailDesc(container.innerHTML);
    }
  }, [videoDetailData?.editorContents]);

  return {
    videoDetailData: videoDetailData as MediaDetailResDto,
    likeVideo() {
      fetcher.media.postMediaLike({ mediaId: id }).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
    bookmarkVideo(bookmark: boolean) {
      console.log('bookmark', bookmark);

      if (bookmark) {
        fetcher.media.postMediaBookmark({ mediaId: id }).then(res => {
          if (res.resultCode === 200) mutate();
        });
      } else {
        fetcher.media.deleteMediaBookmark({ mediaId: id }).then(res => {
          if (res.resultCode === 200) mutate();
        });
      }
    },
    playlistData,
    id,
    setId,
    relatedData,
    downloadVideo(formData: MediaDownloadReqDto) {
      fetcher.media.postMediaDownload(formData).then(res => res);
    },
    mutate,
    mutatePlaylist,
    mutateRelated,
    detailDesc,
    videoElements,
  };
};

export default useVideoDetail;
