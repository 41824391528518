import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ContentCard from 'components/molecules/ContentCard';
import { getContentsGAProps, getGAClickProps } from 'utils/GAUtils';
import Paths from 'commons/paths';

interface MainNewsProps {
  newsList: Array<MediaCardResDto>;
  currentDt: string;
}

const MainNews = (props: MainNewsProps) => {
  const { newsList } = props;
  const history = useHistory();
  const [screenSize, setScreenSize] = useState('lg');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderingCard = (s: MediaCardResDto, className: string, idx: number) => (
    <ContentCard
      {...s}
      currentDt={props.currentDt}
      path={Paths.getPath('newsDetail').replace(
        ':mediaId',
        s.mediaId as string
      )}
      isSwiperSlide={className === 'card' ? false : true}
      key={s.mediaId}
      isNews={s.contentsType === 'NEWS'}
      GAObj={getContentsGAProps({
        contentsType: 'NEWS',
        title: s.title as string,
        tags: s.mediaTag as string,
        category: s.cateNm as string,
        dataContentBelong: 'LG뉴스',
        maker: s.creatorNm as string,
        time: s.videoTotalTime,
        brightcoveId: s.videoId as string,
        mediaContentsType: s.mediaContentsType as string,
        dataContentUrl: `${Paths.getPath('newsDetail').replace(
          ':mediaId',
          s.mediaId as string
        )}`,
        dataImgUrl: `${s.thumbImageUri as string}`,
      })}
    />
  );

  return (
    <>
      <div className="main_section news">
        <div className="main_inner">
          <div className="con_top">
            <h3 className="con_top-title">LG 뉴스</h3>
            <a
              href="#"
              className="con_top-link"
              {...getGAClickProps('모두 보기', 'LG 뉴스')}
              onClick={e => {
                e.preventDefault();
                // history.push({
                //   pathname: Paths.getPath('newsList'),
                //   search: stringify({ keyword: 'LG 뉴스' }),
                // });
                history.push({
                  pathname: Paths.getPath('newsList'),
                });
              }}
            >
              모두 보기
            </a>
          </div>
        </div>
        <div className="main_row">
          {screenSize === 'lg' ? (
            <div className="card_row is-small has-hover newsSwiper">
              {newsList.map((news, idx) => renderingCard(news, 'card', idx))}
            </div>
          ) : (
            <Swiper
              className="card_row newsSwiper is-small"
              slidesPerView={1.4}
              threshold={10}
              breakpoints={{ 768: { slidesPerView: 3.1 } }}
            >
              {newsList.map((news, idx) => (
                <SwiperSlide className="card" key={idx}>
                  {renderingCard(news, '', idx)}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default MainNews;
