import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { WEB_RESOURCE_DOMAIN_URL } from 'commons/constants';
import GradeContext from 'contexts/GradeContext';

interface ProfileImageProps {
  image?: string;
  name: string;
  score: number;
  isAdmin?: boolean;
  isHeader?: boolean;
  tempImage?: string;
  cmtFormOpen?: any;
  isCommentList?: boolean;
}

const ProfileImage = (props: ProfileImageProps) => {
  const gradeContext = useContext(GradeContext);
  const nameRef = useRef<HTMLSpanElement>(null);
  const [fsize, setFsize] = useState('14px');

  const imageSrcUrl = useMemo(() => {
    if (props.tempImage) {
      return props.tempImage;
    }
    if (window.location.hostname.includes('localhost')) {
      return WEB_RESOURCE_DOMAIN_URL + props.image;
    }
    return window.location.origin + props.image;
  }, [props]);

  const getGrade = (score: number) => {
    const list = gradeContext.gradeData.sort((a, b) => {
      if (a.stepNum && b.stepNum) return a.stepNum - b.stepNum;
      else return 0;
    });
    if (list.length === 0) return '';
    if (score <= (list[0].score || 0)) {
      return 'bronze';
    } else if (score <= (list[1].score || 0)) {
      return 'silver';
    } else if (score <= (list[2].score || 0)) {
      return 'gold';
    } else if (score <= (list[3].score || 0)) {
      return 'dia';
    } else return 'crown';
  };

  const grade = useMemo(
    () => getGrade(props.score),
    [props.score, gradeContext.gradeData.map(g => JSON.stringify(g)).join(',')]
  );

  // useEffect(() => {
  //   // console.log('font size',nameRef.current?.offsetWidth, props.cmtFormOpen)
  //   if (nameRef.current) setFsize(`${nameRef.current?.offsetWidth}px`);
  // }, [props.cmtFormOpen, nameRef.current, props]);

  return (
    <div
      className={classNames('profile', {
        'is-dia': !props.isAdmin && grade === 'dia' && !props.isCommentList,
        'is-crown': !props.isAdmin && grade === 'crown' && !props.isCommentList,
      })}
    >
      {props.isAdmin ? (
        <img src={'/assets/images/icon/ico-grade-admin.png'} alt="" className="profile_grade" />
      ) : (
        <>
          {
            !props.isCommentList
            ? (
                <img
                  src={`/assets/images/icon/ico-grade-name-${grade}.png`}
                  alt=""
                  className="profile_grade"
                />
              ) : (
                <img
                  src={'/assets/images/icon/ico-grade-img-unknown.png'}
                  alt=""
                  className="profile_grade"
                />
              )
          }

          {props.image || props.tempImage ? (
            <img
              style={{
                backgroundImage: props.isHeader ? `url('${imageSrcUrl}')` : '',
              }}
              src={imageSrcUrl}
              alt="profile"
              className="profile_img"
            />
          ) : (
            <img
              src={`/assets/images/icon/ico-grade-no-background-person.png`}
              alt=""
              className="profile_img"
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProfileImage;
