import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import useSearch from 'hooks/useSearch';
import SearchBoard from './SearchBoard';
import SearchEvent from './SearchEvent';
import SearchFaq from './SearchFaq';
import SearchNews from './SearchNews';
import SearchVideo from './SearchVideo';
import { ScreenSize } from '../MyPage';
import { useContinue, useLogging, useMyInfo } from 'hooks';
import { callGAEvent, setGAInterfaceMapping } from 'utils/GAUtils';
import SearchCardNews from './SearchCardNews';
import SearchCardComics from './SearchCardComics';
import SearchTopEatsBoard from './SearchTopEatsBoard';
import SearchEventQuiz from './SearchEventQuiz';
import SearchEventSurvey from './SearchEventSurvey';
// 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
// import SearchMentionsBoard from './SearchMentionsBoard';
import SearchDepartmentBoard from './SearchDepartmentBoard';
// 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
// import SearchEventSimpleSurvey from './SearchEventSimpleSurvey';
import SearchSocietyBoard from './SearchSocietyBoard';
import SearchAnnouncementBoard from './SearchAnnouncementBoard';

const SearchResult = () => {
  const { searchResult }: { searchResult: SearchResultListResDto } = useSearch();
  const { getTimeLine } = useContinue();
  const { info } = useMyInfo();
  const { postLogging } = useLogging();

  const keyword = parse(location.search).keyword as string;

  const [screenSize, setScreenSize] = useState<ScreenSize>('lg');

  /**
   * 대메뉴 구분 코드에 따른 검색 결과 건수 가져오기
   *
   * @param {string} categoryType LNB 대메뉴 유형
   */
  const getTotalSearchResultCountByCategoryType = (
    categoryType: 'contents' | 'community' | 'event' | 'faq' | 'announcement'
  ) => {
    // 동영상
    const videoCount = Number(searchResult?.mediaCnt || 0);
    // 매거진
    const magazineCount = Number(searchResult?.cardNewsCnt || 0);
    // 갓찌 웹툰
    const webtoonCount = Number(searchResult?.cardComicsCnt || 0);
    // 뉴스
    const newsCount = Number(searchResult?.newsCnt || 0);
    // 모의고사
    const eventQuizCount = Number(searchResult?.eventQuizCnt || 0);
    // 설문조사 - 일반 설문
    const eventSurveyCount = Number(searchResult?.eventSurveyCnt || 0);
    // 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
    // 간단 설문 조사 - 간단 설문(LG인의 선택)
    // const eventSimpleSurveyCount = Number(searchResult?.eventSimpleSurveyCnt || 0);
    // 상시 이벤트
    const eventCount = Number(searchResult?.eventCnt || 0);
    // 회사 밖 소모임 게시판 검색 결과 건수
    const societyBoardCount = Number(searchResult?.societyBoardCnt || 0);
    // 회사 속 직무 게시판 검색 결과 건수
    const departmentBoardCount = Number(searchResult?.departmentBoardCnt || 0);
    // 맛집 게시판 검색 결과 건수
    const topEatsBoardCount = Number(searchResult?.topEatsBoardCnt || 0);
    // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
    // 칭찬합니다 게시판 검색 결과 건수
    // const mentionsBoardCount = Number(searchResult?.mentionsBoardCnt || 0);
    // 자유 게시판 검색 결과 건수
    const boardCount = Number(searchResult?.boardCnt || 0);
    // LG LIF 공지/안내 게시판 결과 건수
    const announcementBoardCount = Number(searchResult?.announcementBoardCnt || 0);
    // FAQ
    const faqCount = Number(searchResult?.faqCnt || 0);

    // FAQ 건수
    if (categoryType === 'faq') return Number(faqCount);
    // LG LIFE 공지 건수
    if (categoryType === 'announcement') return Number(announcementBoardCount);

    if (categoryType === 'community') {
      return Number(
        societyBoardCount +
          topEatsBoardCount +
          boardCount +
          // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
          // mentionsBoardCount +
          // 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
          // eventSimpleSurveyCount +
          departmentBoardCount
      );
    }

    if (categoryType === 'event') {
      return Number(eventQuizCount + eventSurveyCount + eventCount);
    }

    return Number(
      videoCount + magazineCount + webtoonCount + newsCount
    );
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!info || !searchResult) {
      return;
    }

    setGAInterfaceMapping({
      cateType: 'SEARCH',
      userInfo: info as UserDetailInfoResDto,
      searchKeyword: keyword as string,
      searchResultData: searchResult as SearchResultListResDto,
    });

    callGAEvent();
    postLogging();
  }, [info, searchResult]);

  return (
    <>
      <div className="content_inner">
        <h2 className="search_title">{`“${keyword}” 검색 결과입니다`}</h2>
        <h3 className="search_sub_title">
          콘텐츠 <span className="is-num">({getTotalSearchResultCountByCategoryType('contents')} 개)</span>
        </h3>
        {/* 콘텐츠 - 동영상 */}
        <div className="search_section">
          <SearchVideo
            list={searchResult?.mediaSearchList}
            count={searchResult?.mediaCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
            getTimeLine={getTimeLine}
          />
        </div>
        {/* 콘텐츠 - 갓찌 웹툰 */}
        <div className="search_section">
          <SearchCardComics
            list={searchResult?.cardComicsSearchList}
            count={searchResult?.cardComicsCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
            getTimeLine={getTimeLine}
          />
        </div>
        {/* 콘텐츠 - 매거진 */}
        <div className="search_section">
          <SearchCardNews
            list={searchResult?.cardNewsSearchList}
            count={searchResult?.cardNewsCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
            getTimeLine={getTimeLine}
          />
        </div>
        {/* 콘텐츠 - 보도자료 */}
        <div className="search_section">
          <SearchNews
            list={searchResult?.newsSearchList}
            count={searchResult?.newsCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
            getTimeLine={getTimeLine}
          />
        </div>

        <h3 className="search_sub_title">
          커뮤니티 <span className="is-num">({getTotalSearchResultCountByCategoryType('community')} 개)</span>
        </h3>

        {/* 회사 밖 소모임 게시판 */}
        <div className="search_section board">
          <SearchSocietyBoard
            list={searchResult?.societyBoardSearchList}
            count={searchResult?.societyBoardCnt}
            screenSize={screenSize}
          />
        </div>

        {/* 맛집 게시판 */}
        <div className="search_section board">
          <SearchTopEatsBoard
            list={searchResult?.topEatsBoardSearchList}
            count={searchResult?.topEatsBoardCnt}
            screenSize={screenSize}
          />
        </div>

        {/* 직무 게시판 */}
        <div className="search_section board">
          <SearchDepartmentBoard
            list={searchResult?.departmentBoardSearchList}
            count={searchResult?.departmentBoardCnt}
            screenSize={screenSize}
          />
        </div>

        {/* 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10 */}
        {/* LG인의 선택 */}
        {/*<div className="search_section">*/}
        {/*  <SearchEventSimpleSurvey*/}
        {/*    list={searchResult?.eventSimpleSurveySearchList}*/}
        {/*    count={searchResult?.eventSimpleSurveyCnt}*/}
        {/*    currentDt={searchResult?.currentDt}*/}
        {/*    screenSize={screenSize}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07 */}
        {/* 칭찬 게시판  */}
        {/*<div className="search_section board">*/}
        {/*  <SearchMentionsBoard*/}
        {/*    list={searchResult?.mentionsBoardSearchList}*/}
        {/*    count={searchResult?.mentionsBoardCnt}*/}
        {/*    screenSize={screenSize}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* 자유 게시판  */}
        <div className="search_section board">
          <SearchBoard list={searchResult?.boardSearchList} count={searchResult?.boardCnt} screenSize={screenSize} />
        </div>

        <h3 className="search_sub_title">
          이벤트 <span className="is-num">({getTotalSearchResultCountByCategoryType('event')} 개)</span>
        </h3>
        <div className="search_section">
          <SearchEventQuiz
            list={searchResult?.eventQuizSearchList}
            count={searchResult?.eventQuizCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
          />
        </div>
        <div className="search_section">
          <SearchEventSurvey
            list={searchResult?.eventSurveySearchList}
            count={searchResult?.eventSurveyCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
          />
        </div>
        <div className="search_section">
          <SearchEvent
            list={searchResult?.eventSearchList}
            count={searchResult?.eventCnt}
            currentDt={searchResult?.currentDt}
            screenSize={screenSize}
          />
        </div>

        <h3 className="search_sub_title">
          LG LIFE 공지 <span className="is-num">({getTotalSearchResultCountByCategoryType('announcement')} 개)</span>
        </h3>
        {/* LG LIFE 공지 게시판  */}
        <div className="search_section board">
          <SearchAnnouncementBoard
            list={searchResult?.announcementBoardSearchList}
            count={searchResult?.announcementBoardCnt}
            screenSize={screenSize}
            showTopTitle={false}
          />
        </div>

        <h3 className="search_sub_title">
          FAQ <span className="is-num">({getTotalSearchResultCountByCategoryType('faq')} 개)</span>
        </h3>
        <div className="search_section">
          <SearchFaq list={searchResult?.faqSearchList} count={searchResult?.faqCnt} showTopTitle={false} />
        </div>
      </div>
    </>
  );
};

export default SearchResult;
