import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import paths from 'commons/paths';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useVideoList } from 'hooks';
import { getContentsGAProps } from 'utils/GAUtils';
import { clearListCache } from '../../../utils/LGLifeUtils';
import { ContentCard } from 'components/molecules';
import { ScreenSize } from 'components/pages/MyPage';
import { Tag } from '../../atoms/ControlTag';

const MEDIA_COUNT_TO_SHOW = '4';
const MEDIA_LIST_FULL_COUNT_TO_SHOW = '16';

interface RowContentsProps {
  screenSize: ScreenSize;
  currentDt: string;
  list?: Array<MediaCardResDto>;
  count?: number;
  getTimeLine: (mediaId?: string) => number | undefined;
}

const RowContents = ({ screenSize, currentDt, list = [], count = 0, getTimeLine }: RowContentsProps) => {
  if (count <= 0) return <div className="mypage_empty">신규 콘텐츠가 없습니다</div>;

  return (
    <>
      {screenSize === 'lg' ? (
        <div className="card_row is-small has-hover recentSwiper">
          <div className="card_wrapper">
            {list?.map((media: MediaCardResDto) => (
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={paths
                  .getPath(
                    media.contentsType === 'NEWS'
                      ? 'newsDetail'
                      : 'videoDetail'
                  )
                  .replace(':mediaId', media.mediaId || '')}
                isSwiperSlide={false}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: media.contentsType as string,
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/신규 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${paths
                    .getPath(
                      media.contentsType === 'NEWS'
                        ? 'newsDetail'
                        : 'videoDetail'
                    )
                    .replace(':mediaId', media.mediaId || '')}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            ))}
          </div>
        </div>
      ) : (
        <Swiper
          className="card_row is-small recentSwiper"
          slidesPerView={1.4}
          threshold={70}
          breakpoints={{ 768: { slidesPerView: 3.1 } }}
        >
          {list?.map((media: MediaCardResDto) => (
            <SwiperSlide className="card" key={media.mediaId}>
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={paths
                  .getPath(
                    media.contentsType === 'NEWS'
                      ? 'newsDetail'
                      : 'videoDetail'
                  )
                  .replace(':mediaId', media.mediaId || '')}
                isSwiperSlide={true}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: media.contentsType as string,
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/신규 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${paths
                    .getPath(
                      media.contentsType === 'NEWS'
                        ? 'newsDetail'
                        : 'videoDetail'
                    )
                    .replace(':mediaId', media.mediaId || '')}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

interface Props {
  screenSize?: ScreenSize;
  currentDt?: string;
  getTimeLine?: (mediaId?: string) => number | undefined;
}

const CurrentlyContentsSection = ({
  screenSize = 'lg',
  currentDt = new Date().toString(),
  getTimeLine = () => 0,
}: Props) => {
  const { videosData, searchOption, videoTagsData, setSearchOption } = useVideoList({
    pageItemSize: parseInt(MEDIA_LIST_FULL_COUNT_TO_SHOW),
  });
  const history = useHistory();

  const [videoList, setVideoList] = useState([] as MediaCardResDto[]);
  const [tags, setTags] = useState([] as Tag[]);
  const [sort, setSort] = useState('postDt,desc');
  const [popularitySortType, setPopularitySortType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopState, setIsPopState] = useState(false);

  const controlRef = useRef<HTMLDivElement>(null);

  const videoContentsList = useMemo(() => {
    return videoList.slice(0, parseInt(MEDIA_COUNT_TO_SHOW));
  }, [videoList]);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('currentlyContentsSectionList', JSON.stringify(videoList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  const handleScroll = () => {
    if ((window.scrollY >= 88 && window.innerWidth > 1023) || (window.scrollY >= 61 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  useEffect(() => {
    if (history.action === 'POP' && videoList.length === 0 && localStorage.getItem('currentlyContentsSectionList')) {
      setVideoList(JSON.parse(localStorage.getItem('currentlyContentsSectionList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (listData && history.action === 'POP' && listData.list === 'video') {
      setSort(listData.sort);
      setTags(listData.filter);
      setPopularitySortType(listData.popularitySortType);
    } else {
      window.sessionStorage.clear();
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.onbeforeunload = () => {
      clearListCache();
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (videosData) {
      if (isPopState && videosData.page === currentPage) {
        setIsPopState(false);
        return;
      }

      if (currentPage === 1 && videosData.page === 1) {
        setVideoList(videosData.mediaCardList);
      } else if (videosData.totalAmount > videoList?.length) {
        setVideoList(videoList.concat(videosData.mediaCardList));
      }
    }
  }, [videosData]);

  useEffect(() => {
    // console.log('useEffect currentPage', currentPage);
    setSearchOption({
      ...searchOption,
      tagList: tags
        .filter(t => t.isActive)
        .map(t => {
          return t.title === '신년사' && t.isNYTag ? 'NEWYEARLG' : t.title;
        })
        .join(','),
      sort,
      popularitySortType,
      page: currentPage,
    });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({
        list: 'video',
        sort: sort,
        filter: tags,
        currentPage: currentPage,
        popularitySortType: popularitySortType,
      })
    );
  }, [sort, popularitySortType, currentPage, tags]);

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (videoTagsData?.mediaBasicTagList && ['PUSH', 'REPLACE'].includes(history.action)) {
      const newTags = videoTagsData.mediaBasicTagList.map((tag: MediaBasicTagResDto) => ({
        orderNum: tag.orderNum === 1 ? tag.orderNum : Number(tag.orderNum || 1) + 1,
        title: tag.tagNm,
        isActive: tag.tagNm === 'LGCC',
      }));

      const newYearsTag = {
        title: '신년사',
        isActive: true,
        orderNum: 2,
        isNYTag: true,
      } as Tag;

      // 순서 수정된 태그 리스트
      const resultTagsList = [
        ...newTags.filter(firstItem => firstItem.orderNum === 1),
        newYearsTag,
        ...newTags.filter(value => value.orderNum > newYearsTag.orderNum),
      ];

      setTags(resultTagsList);
    } else if (videoTagsData?.mediaBasicTagList && (listData.list !== 'video' || listData.filter.length === 0)) {
      const newTags = videoTagsData.mediaBasicTagList.map((tag: MediaBasicTagResDto) => ({
        orderNum: tag.orderNum === 1 ? tag.orderNum : Number(tag.orderNum || 1) + 1,
        title: tag.tagNm,
        isActive: tag.tagNm === 'LGCC',
      }));

      const newYearsTag = {
        title: '신년사',
        isActive: true,
        orderNum: 2,
        isNYTag: true,
      } as Tag;

      // 순서 수정된 태그 리스트
      const resultTagsList = [
        ...newTags.filter(firstItem => firstItem.orderNum === 1),
        newYearsTag,
        ...newTags.filter(value => value.orderNum > newYearsTag.orderNum),
      ];

      setTags(resultTagsList);
    }
  }, [videoTagsData]);

  if (!videoList) return <></>;

  return (
    <div className="mypage_section">
      <div className="mypage_inner">
        <div className="mypage_top">
          <h3 className="mypage_top_title">신규 콘텐츠</h3>
          {(videoList?.length || 0) > parseInt(MEDIA_COUNT_TO_SHOW) ? (
            <Link to={`${paths.getPath('currentlyContents')}`} className="mypage_top_link">
              모두 보기
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        <RowContents
          screenSize={screenSize}
          currentDt={currentDt}
          list={videoContentsList}
          count={videoContentsList?.length || 0}
          getTimeLine={getTimeLine}
        />
      </div>
    </div>
  );
};

export default CurrentlyContentsSection;
