import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { sortAsc } from 'utils/LGLifeUtils';
import useMyInfo from './useMyInfo';
import { useState } from 'react';

const useEPBanners = () => {
  const { info } = useMyInfo();

  const [boardPage, setBoardPage] = useState<number>(1);
  const [eventPage, setEventPage] = useState<number>(1);

  /**
   * EP 계열사 배너 링크 상세 정보
   * @param params
   */
  const getEpGroupHeroDetailInfo = async (params: EpGroupHeroLinkDetailReqDto) => {
    return fetcher.epPanel.getGroupHeroLinkDetailInfo(params);
  }

  let { data: banners } = useSWR<EpBannerListResDto>([endpoints.epPanel.banners, info], () =>
    fetcher.epPanel.getBanners({ isLogin: !!info }).then(res => {
      return res;
    })
  );

  let { data: heroes } = useSWR<EpHeroListResDto>(endpoints.epPanel.heroes, () =>
    fetcher.epPanel.getHeroes({}).then(res => {
      return res;
    })
  );

  const { data: displayEpGroupHeroList } = useSWR<EpGroupHeroListResDto>(
    info ? endpoints.epPanel.displayGroupHeroList : null,
    () =>
      fetcher.epPanel.getDisplayGroupHeroList({ isLogin: !!info }).then(res => {
        return res;
      })
  );

  const { data: displayEpEventList } = useSWR<EpEventDisplayListResDto>(
    [endpoints.epPanel.displayEventList, eventPage],
    () =>
      fetcher.epPanel.getDisplayEventList(eventPage).then(res => {
        return res;
      })
  );

  const { data: displayEpBoardList } = useSWR<EpBoardDisplayListResDto>(
    [endpoints.epPanel.displayBoardList, boardPage],
    () =>
      fetcher.epPanel.getDisplayBoardList(boardPage).then(res => {
        return res;
      })
  );

  if (banners && banners.epBannerList) {
    banners.epBannerList = sortAsc(banners.epBannerList) as EpBannerResDto[];
  }

  if (displayEpGroupHeroList?.epGroupHeroList) {
    displayEpGroupHeroList.epGroupHeroList = sortAsc(
      displayEpGroupHeroList.epGroupHeroList.filter(hero => hero.groupCode === info?.groupCode)
    ) as EpGroupHeroResDto[];
  }

  return {
    banners,
    heroes,
    displayEpGroupHeroList,
    displayEpEventList,
    displayEpBoardList,
    boardPage,
    setBoardPage,
    eventPage,
    setEventPage,
    abInitial: (data: AddAbTestInfoReqDto) => {
      fetcher.epPanel.postABTestInitial(data).then(res => {});
    },
    abDuplicate: (data: AddAbTestInfoReqDto) => {
      fetcher.epPanel.postABTestDuplicate(data).then(res => {});
    },
    getEpGroupHeroDetailInfo
  };
};

export default useEPBanners;
