import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';

export interface BoardsRequest {
  cateList?: string;
  groupCompanyList?: string;
  page?: number;
  popularitySortType?: string;
  seachWord?: string;
  searchWordTotal?: string;
  boardType: string;
  size: number;
  sort: Array<string>;
}

export interface BoardDetailRequest {}

export interface BoardWriteReq {
  attachFiles: File[];
  cateId: string;
  contents: string;
  contentsText: string;
  subCateId: string;
  boardType: string;
  tags: Array<string>;
  title: string;
}

export interface BoardUpdateReq extends BoardWriteReq {
  boardId: string;
  uploadedFiles: AttachFileResDto[];
}

export interface PopularContentRequest {}

export interface BoardLikeReq {
  boardId: string;
}

export interface BoardDeleteReq {
  boardId: string;
}

export default {
  getBoards: (params: BoardsRequest): Promise<any> =>
    request({
      url: endpoints.boards.board,
      method: 'get',
      params,
    }),
  getBoardDetail: (url: string, params?: BoardDetailRequest): Promise<any> =>
    request({
      url,
      method: 'get',
      params,
    }),
  /** 인기 콘텐츠 */
  getPopularContent: (params: PopularContentRequest): Promise<any> =>
    request({
      url: endpoints.boards.popularContent,
      method: 'get',
      params,
    }),
  postBoardWrite: (params: BoardWriteReq): Promise<any> => {
    const postParams = {
      title: params.title,
      tags: params.tags,
      subCateId: params.subCateId,
      boardType: params.boardType || 'FREE',
      contents: params.contents,
      contentsText: params.contentsText,
      cateId: params.cateId,
    };

    if (params.subCateId) {
      postParams['subCateId'] = params.subCateId;
    }

    for (let i = 0; i < params.attachFiles.length; i++) {
      postParams['attachFiles[' + i + '].attachFile'] = params.attachFiles[i];
      postParams['attachFiles[' + i + '].attachFileId'] = '0';
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: endpoints.boards.boardWrite,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  putBoardUpdate: (url: string, params: BoardUpdateReq): Promise<any> => {
    const postParams = {
      boardId: params.boardId,
      title: params.title,
      tags: params.tags,
      subCateId: params.subCateId,
      boardType: params.boardType || 'FREE',
      contents: params.contents,
      contentsText: params.contentsText,
      cateId: params.cateId,
    };

    const uploadedFilesLength = params.uploadedFiles.length;

    for (let i = 0; i < uploadedFilesLength; i++) {
      postParams['attachFiles[' + i + '].attachFileId'] = params.uploadedFiles[i].fileId;
    }

    for (let i = 0; i < params.attachFiles.length; i++) {
      postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFile'] = params.attachFiles[i];
      postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFileId'] = '0';
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  postBoardLike: (params: BoardLikeReq): Promise<any> =>
    request({
      url: endpoints.boards.boardLike,
      method: 'post',
      params,
    }),
  deleteBoard: (url: string, params?: BoardDeleteReq): Promise<any> =>
    request({
      url,
      method: 'delete',
      params,
    }),
  shareBoard: (params: ShareReqDto): Promise<any> =>
    request({
      url: endpoints.boards.boardShare,
      method: 'post',
      data: params,
    }),
  /** 홍보 게시물 일일 업로드 가능한 잔여 건수 */
  getUploadAdvertisementCount: (
    params: BoardUploadAdvertisementCountInfoReqDto
  ): Promise<BoardAdvertisementUploadCountInfoResDto> =>
    request({
      url: endpoints.boards.allowedAdvertisementUploadCount,
      method: 'post',
      data: params,
    }),
};
