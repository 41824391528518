import React, { ReactElement } from 'react';

const Error404 = (): ReactElement => {
  return (
    <div className="container login_sub">
      <div className="login_inner">
        <div className="login_top">
          <div className="login_logo">
            <h1>
              <span className="ab_text">LG LIFE</span>
            </h1>
          </div>
          <div className="error_404">
            <img src="/assets/images/illust/illust-login-404error.png" alt="login" className="error_404_img" />
          </div>
        </div>

        <div className="login_box is-complete">
          <div className="login_complete">
            <div className="title">오류가 발생하였습니다.</div>
            <div className="subtxt">
              {/* 본 서비스는 크롬 / 엣지 / IE 11버전 이상에서만
              <br className="is-mobile" /> 서비스 가능합니다.
              <br className="is-pc" />
              양해 부탁드립니다. */}
              404: 요청하신 문서를 찾을 수 없습니다.
              <br />
              서비스 이용에 불편을 드려 죄송합니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
