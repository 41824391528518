import classNames from 'classnames';
import ProfileImage from 'components/atoms/ProfileImage';
import ProfilePopup from 'components/organisms/Popup/ProfilePopup';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { all_click_datalayer_push, getContentsButtonGAProps } from 'utils/GAUtils';
import { getDangerouslySetInnerHTMLValueBySanitizedHtml, getHtmlFormatByEscapeHtmlData } from 'utils/LGLifeUtils';
import { relativeTime } from 'utils/utils';
import dompurify from 'dompurify';
import MyInfoContext from 'contexts/MyInfoContext';
import MentionModifyComment from '../MentionComment/MentionModifyComment';
import { getCommentContentsByReplacedMentionTagToHTMLTag } from '../../../utils/mentionUtils';
import { CommentPostType } from '../../../types/enum/CommentPostType';

interface SimpleCommentProps extends CommentResDto {
  likeReply?: (commentId: string) => Promise<any>;
  setReply?: () => void;
  contentsId: string;
  commentPostType: CommentPostType;
  tags: string;
  title: string;
  category: string;
  contentCreatorNm: string;
  time?: number;
  brightcoveId?: string;
  mediaContentsType?: string;
  currentDt: string;
  modify?: (params: CommentModifyReqDto) => Promise<any>;
  delete?: (params: CommentDeleteReqDto) => Promise<any>;
  mutateCmt?: () => {};
  commentSearchMailWidth: () => void;
  isBest?: boolean;
  setMentionUserId?: any;
  setMentionProfilePopup?: any;
}

const SimpleComment = (props: SimpleCommentProps) => {
  // const { info } = useMyInfo();
  const info = useContext(MyInfoContext);
  const [userInfo, setUserInfo] = useState<UserDetailInfoResDto>();
  const [profilePopup, setProfilePopup] = useState(false);
  const [cmtDeletePopup, setCmtDeletePopup] = useState(false);
  const [modCancelPopup, setModCancelPopup] = useState(false);

  const [comment, setComment] = useState<string>('');

  const isMobile = useMemo(() => window.innerWidth < 1024, [window.innerWidth]);

  const cmtItemDescRef = useRef<HTMLDivElement>(null);

  const cmtItemWrapRef = useRef<HTMLDivElement>(null);
  const mobModifyRef = useRef<HTMLDivElement>(null);

  const modifyCmtDivRef = useRef<HTMLDivElement>(null);
  const modifyCmtInputRef = useRef<HTMLDivElement>(null);

  const mobModifyCmtDivRef = useRef<HTMLDivElement>(null);
  const mobModifyCmtInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (info) setUserInfo(info);
  }, [info]);

  useEffect(() => {
    if (!props.contents) return;
    setComment(getCommentContentsByReplacedMentionTagToHTMLTag(props.contents));
  }, [props.contents]);

  useEffect(() => {
    if (cmtItemDescRef.current && props.setMentionUserId && props.setMentionProfilePopup) {
      cmtItemDescRef.current.querySelectorAll('.mention_tag').forEach(tag => {
        tag.addEventListener('click', () => {
          props.setMentionUserId(tag.getAttribute('data-mention-user-id') as string);
          props.setMentionProfilePopup(true);
        });
      });
    }
  }, [comment]);

  return (
    <>
      <div className="comment_item">
        {(props.delYn === 'N' || (props.delUser === 'ADMIN' && props.creatorId === info.userId)) && (
          <div
            className={classNames('comment_item_wrap', { 'is-mine': props.creatorId === userInfo?.userId })}
            ref={cmtItemWrapRef}
          >
            <div className="comment_item_profile">
              {/* <img src={props.creatorProfileUri} alt="" /> */}
              <ProfileImage
                name={props.creatorNm as string}
                score={props.creatorActScore as number}
                image={props.creatorProfileUri}
                isAdmin={props.creatorAdminYn === 'Y' || props.adminRegYn === 'Y'}
                isCommentList={true}
              />
            </div>
            <div className="comment_item_con">
              <div className="comment_item_name">
                {/* mobile 여부 판단 후 노출 여부 결정 */}
                {props.mobileRegYn === 'Y' && (
                  <span className="comment_item_mob">
                    <img src="/assets/images/icon/ico-mob-32.png" alt="모바일 아이콘" />
                  </span>
                )}
                <a
                  className="comment_item_name_link"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (props.creatorAdminYn === 'N' && props.creatorRetireYn === 'N' && props.adminRegYn === 'N')
                      setProfilePopup(true);
                  }}
                >
                  {props.creatorAdminYn === 'Y' || props.adminRegYn === 'Y' ? (
                    '관리자'
                  ) : (
                    <>
                      {props.creatorNm}
                      {props.creatorRetireYn === 'N' && (
                        <>
                          <span style={{ paddingLeft: '4px', color: 'lightgray' }}>|</span>
                          <span className="comment_item_company" style={{ paddingLeft: '4px' }}>
                            {props.creatorGroupNm}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </a>
                <span className="comment_item_time">
                  {info.isAdmin ? relativeTime(props.createdDt, props.currentDt) : ''}
                  {props.modifiedDt !== props.createdDt ? ' (수정됨)' : ''}
                </span>
              </div>
              <div
                className="comment_item_desc"
                ref={cmtItemDescRef}
                dangerouslySetInnerHTML={{ __html: getDangerouslySetInnerHTMLValueBySanitizedHtml(comment) }}
              ></div>
              <div className="comment_item_sub">
                <a
                  className={classNames('comment_item_likes', { 'is-active': props.likeStatus === 'Y' })}
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    props.likeReply &&
                      props.likeReply(props.commentId as string).then(res => {
                        if (res.resultCode === 200) props.setReply && props.setReply();
                      });
                    all_click_datalayer_push(e.currentTarget);
                  }}
                  {...getContentsButtonGAProps({
                    buttonType: '좋아요',
                    contentType: props.commentPostType,
                    tags: props.tags as string,
                    title: props.title as string,
                    time: props.time ? props.time : -1,
                    dataContentCategory: props.category as string,
                    creatorNm: props.contentCreatorNm as string,
                    brightcoveId: props.brightcoveId as string,
                    mediaContentsType: props.mediaContentsType as string,
                  })}
                >
                  {props.likeCnt}
                </a>
              </div>
              <button className="comment_dot" onClick={() => cmtItemWrapRef.current?.classList.toggle('is-active')}>
                <img src="/assets/images/icon/ico-comment-dot.png" alt="comment menu" className="comment_dot_img" />
              </button>
              {/* <!-- 답글 수정/삭제 메뉴 --> */}
              <div className="comment_dot_menu" style={{ zIndex: 80 }}>
                <div className="comment_dot_list" style={{ zIndex: 80 }}>
                  <div className="comment_dot_item">
                    <a
                      className="comment_dot_modi"
                      onClick={() => {
                        cmtItemWrapRef.current?.classList.remove('is-active');
                        cmtItemWrapRef.current?.classList.add('is-modify');
                        if (isMobile) {
                          mobModifyRef.current?.classList.add('is-active');
                          document.querySelector('nav')?.style.setProperty('display', 'none');
                          mobModifyCmtDivRef.current?.focus();
                        } else {
                          modifyCmtDivRef.current?.focus();
                        }
                      }}
                    >
                      수정
                    </a>
                  </div>
                  <div className="comment_dot_item">
                    <a className="comment_dot_del" onClick={() => setCmtDeletePopup(true)}>
                      삭제
                    </a>
                  </div>
                  <div className="comment_dot_cancel is-mobile">
                    <button className="popup_confirm ">취소</button>
                  </div>
                  <button
                    className="comment_dot_close is-mobile"
                    onClick={() => {
                      cmtItemWrapRef.current?.classList.remove('is-active');
                    }}
                  >
                    <img src="/assets/images/icon/ico-action-24-close.svg" alt="close" />
                  </button>
                </div>
              </div>
              {/* <!-- 답글 수정 인풋 --> */}
              <div className="comment_modify">
                <div className="comment_input">
                  <MentionModifyComment
                    {...props}
                    contents={props.contents as string}
                    isReply={true}
                    isBest={props.isBest}
                    isFocusStart={true}
                    setReply={props.setReply}
                    cmtDivRef={modifyCmtDivRef}
                    cmtInputRef={modifyCmtInputRef}
                    cmtItemWrapRef={cmtItemWrapRef}
                    commentSearchMailWidth={props.commentSearchMailWidth}
                    commentIndex={props.orderNum as number}
                  />
                </div>
              </div>
              {/* <!-- 답글 수정 인풋(모바일) --> */}
              <div className="comment_modify_mo">
                <div className="comment_fixed" ref={mobModifyRef}>
                  <div className="comment_fixed_inner">
                    <div className="comment_profile">
                      <ProfileImage
                        name={props.creatorNm as string}
                        score={props.creatorActScore as number}
                        image={props.creatorProfileUri}
                        isAdmin={props.creatorAdminYn === 'Y' || props.adminRegYn === 'Y'}
                        isCommentList={true}
                      />
                    </div>
                    <MentionModifyComment
                      {...props}
                      contents={props.contents || ''}
                      isReply={true}
                      isFocusStart={true}
                      setReply={props.setReply}
                      cmtDivRef={mobModifyCmtDivRef}
                      cmtInputRef={mobModifyCmtInputRef}
                      cmtItemWrapRef={cmtItemWrapRef}
                      isMobile={true}
                      mobModifyRef={mobModifyRef}
                      setModCancelPopup={setModCancelPopup}
                      commentIndex={props.orderNum as number}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.delYn === 'Y' && (props.delUser === 'SELF' || props.creatorId !== info.userId) && (
          <div className="comment_delete">{props.contents}</div>
        )}
      </div>
      {profilePopup && <ProfilePopup userId={props.creatorId as string} close={() => setProfilePopup(false)} />}
      {cmtDeletePopup && (
        <div className={classNames('confirm_popup', 'comment_del_popup', { 'is-active': cmtDeletePopup })}>
          <div className="popup_dim"></div>
          <div className="popup_inner">
            {/* <p className="confirm_popup_text">해당 댓글을 삭제 하겠습니까?</p> */}
            <p className="confirm_popup_text">해당 답글을 삭제 하겠습니까?</p>
            <ul className="confirm_button_list">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button is-dim popup_button_cancel"
                  onClick={() => setCmtDeletePopup(false)}
                >
                  취소
                </button>
              </li>
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm"
                  onClick={() => {
                    props.delete &&
                      props
                        .delete({
                          commentId: props.commentId as string,
                          contentsId: props.contentsId,
                          contentsType: props.commentPostType,
                        })
                        .then(res => {
                          if (res.resultCode === 200) props.setReply && props.setReply();
                        });
                    setCmtDeletePopup(false);
                  }}
                >
                  삭제
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {modCancelPopup && (
        <div className={classNames('confirm_popup', 'comment_modi_popup', { 'is-active': modCancelPopup })}>
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text">수정사항을 삭제하시겠습니까?</p>
            <ul className="confirm_button_list">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_cancel"
                  onClick={() => {
                    setModCancelPopup(false);
                  }}
                >
                  계속 작성
                </button>
              </li>
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm"
                  onClick={() => {
                    setModCancelPopup(false);
                    cmtItemWrapRef.current?.classList.remove('is-modify');
                    mobModifyRef.current?.classList.remove('is-active');
                    document.querySelector('nav')?.style.setProperty('display', '');
                  }}
                >
                  삭제
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SimpleComment;
