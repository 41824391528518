import React, { Fragment } from 'react';
import { Faq, FaqCate } from '..';
import FaqItem from './FaqItem';
import { getHtmlFormatByEscapeHtmlData } from '../../../../utils/LGLifeUtils';

interface FaqAccordionProps {
  faqCateList: FaqCategoryListResDto[];
}

const FaqAccordion = (props: FaqAccordionProps) => {
  return (
    <>
      <div className="faq_list">
        {props.faqCateList.map((faqCate, index) => (
          <Fragment key={index}>
            <div className="faq_header">
              <strong className="faq_title">{getHtmlFormatByEscapeHtmlData(faqCate.cateNm as string)}</strong>
            </div>
            <ul className="faq_accor">
              {faqCate.faqList &&
                faqCate.faqList
                  .sort((a, b) => {
                    if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
                    else return 1;
                  })
                  .map(faq => <FaqItem {...faq} key={faq.faqId} />)}
            </ul>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default FaqAccordion;
