import React, { ReactElement } from 'react';

const AppError = (): ReactElement => {
  return (
    <div className="container login_sub">
      <div className="login_inner">
        <div className="login_top">
          <div className="login_logo">
            <h1>
              <span className="ab_text">LG LIFE</span>
            </h1>
          </div>
          <div className="error_404"></div>
        </div>

        <div className="login_box is-complete">
          <div className="login_complete">
            <div className="title">모바일용 LG LIFE 는 전용 모바일 App을 통해서만 이용하실 수 있습니다.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppError;
