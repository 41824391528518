import { useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { sortAsc } from 'utils/LGLifeUtils';
import useMyInfo from '../../useMyInfo';

const useBoardList = () => {
  const { info } = useMyInfo();
  const [searchOptions, setSearchOptions] = useState<any>({
    size: 12,
    sort: 'createdDt,desc',
    boardType: 'FREE',
    searchWord: '',
    searchWordTotal: '',
    cateList: '',
    groupCompanyList: '',
    page: 0,
  });

  const { data: popularContent } = useSWR<any>(info ? endpoints.boards.popularContent : null, () =>
    fetcher.boards.getPopularContent({}).then(res => {
      return res;
    })
  );

  const { data: boards, mutate: boardListMutate } = useSWR<any>(
    info && searchOptions.page > 0 ? [endpoints.boards.board, searchOptions] : null,
    () =>
      fetcher.boards.getBoards(searchOptions).then(res => {
        return res;
      })
  );

  const { data: category } = useSWR<CategoryListResDto>(info ? [endpoints.common.category, 'BOARD'] : null, () =>
    fetcher.common.getCategory({ cateType: 'BOARD' }).then(res => {
      return res;
    })
  );

  const { data: subCategory } = useSWR<CategoryListResDto>(info ? [endpoints.common.category, 'SUB'] : null, () =>
    fetcher.common.getCategory({ cateType: 'SUB' }).then(res => {
      return res;
    })
  );

  /**
   * 홍보 게시물 오늘 업드한 게시글 건수
   * @param params
   */
  const getBoardUploadAdvertisementCountInfo = async (params: BoardUploadAdvertisementCountInfoReqDto) => {
    return await fetcher.boards.getUploadAdvertisementCount(params);
  };

  return {
    boardResDtoList: boards as BoardListResDto,
    boardListMutate,
    popularContent,
    searchOptions,
    setSearchOptions,
    category: category && !!category?.categoryList?.length ? category.categoryList?.sort((a, b) => a.orderNum - b.orderNum) : ([] as CategoryResDto[]),
    subCategory:
      subCategory && !!subCategory?.categoryList?.length ? subCategory.categoryList?.sort((a, b) => a.orderNum - b.orderNum) : ([] as CategoryResDto[]),
    getBoardUploadRemainingCountInfo: getBoardUploadAdvertisementCountInfo,
    postBoardWrite(
      title: string,
      tags: Array<string>,
      contents: string,
      contentsText: string,
      cateId: string,
      subCateId: string,
      attachFiles: File[]
    ) {
      return fetcher.boards.postBoardWrite({
        attachFiles,
        cateId,
        contents,
        contentsText,
        subCateId,
        boardType: 'FREE',
        tags,
        title,
      });
    },
  };
};

export default useBoardList;
