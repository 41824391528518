export enum BoardType {
  /* 자유 게시판 */
  FREE = 'FREE',
  /* 회사 옆 맛집 게시판 */
  TOP_EATS = 'TOP_EATS',
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /* 칭찬 게시판 */
  // MENTIONS_BOARD = 'MENTIONS_BOARD',
  /* 회사 속 직무 게시판 */
  DEPARTMENT_BOARD = 'DEPARTMENT_BOARD',
  /* 회사 밖 소모임 게시판 */
  SOCIETY_BOARD = 'SOCIETY_BOARD',
  /* LG LIFE 공지 게시판 */
  ANNOUNCEMENT_BOARD = 'ANNOUNCEMENT_BOARD',
}
