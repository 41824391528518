import auth from './auth';
import comment from './comment';
import common from './common';
import epPanel from './epPanel';
import main from './main';
import notice from './notice';
import videos from './videos';
import boards from './boards';
import media from './media';
import event from './event';
import faq from './faq';
import qna from './qna';
import recommends from './recommends';
import story from './story';
import myPage from './myPage';
import mobile from './mobile';
import search from './search';
import celebration from './celebration';
import topEatsBoards from './topEatsBoards';
import tempBoards from './tempBoards';
import popupInfo from './popupInfo';
import mentionsBoards from './mentionsBoards';
import executiveAppointment from './executiveAppointment';
import departmentBoards from './departmentBoards';
import societyBoards from './societyBoards';
import announcementBoards from './announcementBoards';

export default {
  auth,
  comment,
  common,
  popupInfo,
  epPanel,
  main,
  notice,
  videos,
  /** 자유 게시판 */
  boards,
  /** 맛집 게시판 */
  topEatsBoards,
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 칭찬합니다 게시판 */
  // mentionsBoards,
  /** 직무 게시판 */
  departmentBoards,
  /** 소모임 게시판 */
  societyBoards,
  /** 공지 게시판 */
  announcementBoards,
  /** 게시판 임시 저장 */
  tempBoards,
  media,
  event,
  faq,
  qna,
  recommends,
  story,
  myPage,
  mobile,
  search,
  celebration,
  executiveAppointment,
};
