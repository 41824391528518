import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { useMyInfo, useAuth, useCommon, useTokens, useSaveEmail } from 'hooks';
import { getPageQuery, mobileCheck } from 'utils/utils';
import Paths from 'commons/paths';
import AccountSelect from './components/AccountSelect';
import { getGALoginClickProps, all_click_datalayer_push } from 'utils/GAUtils';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import QnaPopup from './components/QnaPopup';
import { getHtmlFormatByEscapeHtmlData, openLeftSideMenu, setCicUpdate } from '../../../utils/LGLifeUtils';
import useNoneLoginFaq from '../../../hooks/useNoneLoginFaq';
import NoneLoginFaqPopup from './components/NoneLoginFaq/NoneLoginFaqPopup';

const Login = ({ history }: RouteComponentProps) => {
  const { companies, domains, setGroupCode } = useCommon();
  const { postSignIn } = useAuth();
  const { saveTokens } = useTokens();
  const { savedGroupCode, savedEmail, savedDomain, saveEmail, removeEmail } = useSaveEmail();

  const [popupOpen, setPopupOpen] = useState<number>(-1);
  const { info, setIsLogin } = useMyInfo();

  const { noneLoginFaqData, noneLoginFaqCategory } = useNoneLoginFaq();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companySelectedIndex, setCompanySelectedIndex] = useState(-1);
  const [domainSelectedIndex, setDomainSelectedIndex] = useState(-1);

  const [isWarning, setIsWarning] = useState(false);
  const [isSaveEmail, setIsSaveEmail] = useState(false);
  const [failCount, setFailCount] = useState(-1);

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [isQnaPopup, setIsQnaPopup] = useState(false);

  /** 선택한 비 로그인 FAQ 정보 */
  const [selectedNoneLoginFaqInfo, setSelectedNoneLoginFaqInfo] = useState<FaqResDto>();

  /** 비 로그인 FAQ 카테고리가 존재하는지 여부 */
  const [hasNoneLoginFaqCategory, setHasNoneLoginFaqCategory] = useState<boolean>(false);
  /** 비 로그인 faq 팝업 노출 여부 */
  const [showNoneLoginFaqPopup, setShowNoneLoginFaqPopup] = useState<boolean>(false);

  useEffect(() => {
    setHasNoneLoginFaqCategory(!!noneLoginFaqCategory?.length);
  }, [noneLoginFaqCategory]);

  useEffect(() => {
    if (savedGroupCode && savedEmail && savedDomain) {
      setIsSaveEmail(true);
    }
  }, [savedGroupCode, savedEmail, savedDomain]);

  useEffect(() => {
    if (companySelectedIndex > -1) {
      setGroupCode(companies?.groupCompanyList?.[companySelectedIndex]?.groupCode as string);
    }
  }, [companySelectedIndex]);

  useEffect(() => {
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, [savedEmail]);

  useEffect(() => {
    if (companies && savedGroupCode) {
      const index = companies.groupCompanyList?.findIndex(company => company.groupCode === savedGroupCode) as number;

      if (index > -1) {
        setCompanySelectedIndex(index);
      }
    }
  }, [companies, savedGroupCode]);

  useEffect(() => {
    if (domains) {
      if (!domains.groupDomainList) {
        return;
      }

      if (domains.groupDomainList.length === 1) {
        setDomainSelectedIndex(0);
      } else {
        setDomainSelectedIndex(-1);
      }

      if (savedDomain) {
        const index = domains.groupDomainList.findIndex(domain => domain.domainNm === savedDomain);

        if (index > -1) {
          setDomainSelectedIndex(index);
        }
      }
    }
  }, [domains, savedDomain]);

  const onClickCompany = index => {
    setIsWarning(false);
    setCompanySelectedIndex(index);
  };

  const onClickDomain = index => {
    setIsWarning(false);
    setDomainSelectedIndex(index);
  };

  useEffect(() => {
    if (info) {
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();

      let { redirect } = params as { redirect: string };

      if (redirect) {
        redirect = redirect.substr(urlParams.origin.length);
      }

      history.replace(redirect || '/');
      return;
    }

    // 회원 정보가 없을 경우 LNB 펼침 상태로 변경 (Local Storage)
    localStorage.setItem('isOpenedLNB', JSON.stringify(true));
  }, [info]);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWarning(false);
    setEmail(e.target.value.trim());
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWarning(false);
    setPassword(e.target.value.trim());
  };

  const onKeyPressPassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const onClickLogin = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    login();
  };

  const login = () => {
    if (companySelectedIndex === -1) {
      setAlertPopContents('회사를 선택하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (email.trim() === '') {
      setAlertPopContents('이메일을 입력하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (domainSelectedIndex === -1) {
      setAlertPopContents('도메인을 선택하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (password.trim() === '') {
      setAlertPopContents('패스워드를 입력하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    const completedEmail = email + '@' + domains?.groupDomainList?.[domainSelectedIndex]?.domainNm;

    postSignIn(completedEmail, password)
      .then(res => {
        if (res.resultCode === 200) {
          if (isSaveEmail) {
            saveEmail(
              companies?.groupCompanyList?.[companySelectedIndex]?.groupCode as string,
              email,
              domains?.groupDomainList?.[domainSelectedIndex]?.domainNm as string,
            );
          } else {
            removeEmail();
          }

          localStorage.clear();
          saveTokens(res.accessToken, res.refreshToken);
          setIsLogin(true);
          setCicUpdate(res.shouldSyncCic);
        } else if (res.resultCode === 500) {
          setAlertPopContents('서버 오류');
          setIsAlertPopupOpen(true);
        } else if (res.resultCode === 1004) {
          if (res.loginFailCnt) setFailCount(res.loginFailCnt);
          else setFailCount(-1);
          setIsWarning(true);
        } else {
          setAlertPopContents(res.resultMessage);
          setIsAlertPopupOpen(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onChangeSaveEmail = () => {
    setIsSaveEmail(!isSaveEmail);
  };

  /**
   * 비 로그인 FAQ 답변 보기 팝업 열기
   * @param nonFaqItem
   */
  const onClickNonFaqItemAnswer = (nonFaqItem: FaqResDto) => {
    setSelectedNoneLoginFaqInfo(nonFaqItem);
    setShowNoneLoginFaqPopup(true);
  }

  return (
    <div className="wrapper">
      <div className="container login">
        <div className="login_inner">
          <div className="login_top">
            <div className="login_logo">
              <h1>
                <span className="ab_text">LG LIFE</span>
              </h1>
            </div>
          </div>

          <div className="login_box">
            <div className="login_title">로그인</div>
            <div className="login_con">
              <div className="account_row">
                <div className="account_item">
                  <div className="account_title">회사명</div>
                  <AccountSelect
                    initial={'회사선택'}
                    selectedIndex={companySelectedIndex}
                    onClickItem={onClickCompany}
                    list={
                      companies && companies.groupCompanyList && companies.groupCompanyList?.map(cate => cate.groupNm)
                    }
                  />
                </div>
              </div>

              <div className={classNames(['account_row', 'is-email'])}>
                <div className="account_item">
                  <div className="account_title">이메일</div>
                  <input
                    type="text"
                    className="account_value"
                    title="이메일"
                    placeholder="이메일 주소"
                    onChange={onChangeEmail}
                    value={email}
                  />
                  <>
                    <span className="account_at">@</span>
                    <AccountSelect
                      initial={'선택'}
                      selectedIndex={domainSelectedIndex}
                      onClickItem={onClickDomain}
                      list={domains && domains.groupDomainList && domains.groupDomainList.map(cate => cate.domainNm)}
                    />
                  </>
                </div>
              </div>
              <div className="account_row">
                <div className="account_item">
                  <div className="account_title">비밀번호</div>
                  <input
                    type="password"
                    className="account_value"
                    title="비밀번호"
                    placeholder="비밀번호 입력"
                    onKeyPress={onKeyPressPassword}
                    onChange={onChangePassword}
                    value={password}
                  />
                </div>
              </div>
              <div className="login_check">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    className="is-blind"
                    id="emailCheck"
                    checked={isSaveEmail}
                    onChange={onChangeSaveEmail}
                  />
                  <label className="checkbox_label" htmlFor="emailCheck">
                    이메일 저장
                  </label>
                </div>

                <Link className="login_check_link" to={Paths.getPath('findpw')}>
                  비밀번호 찾기
                </Link>
              </div>
              <div className={classNames('login_btn', { 'is-error': isWarning })}>
                <a
                  href="#;"
                  className="login_link is-active"
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                    onClickLogin(e);
                  }}
                  {...getGALoginClickProps({
                    memberAffiliate: domains?.groupDomainList?.[domainSelectedIndex]?.domainNm,
                  })}
                >
                  로그인
                </a>
                <div className="login_warn">
                  {failCount >= 0 ? (
                    <>
                      잘못된 비밀번호입니다.
                      <br />
                      비밀번호 {failCount}회 오류입니다. 5회 틀릴 경우 계정이 잠금처리 됩니다.
                      <br />
                      비밀번호를 찾아야하는 경우 '비밀번호 찾기'를 이용해주세요.
                    </>
                  ) : (
                    <>가입하지 않은 이메일입니다.</>
                  )}
                </div>
              </div>
              <div className="login_more">
                <div className="login_more_section">
                  <Link className="login_more_link" to={Paths.getPath('confirmMember')}>
                    회원 가입
                  </Link>
                </div>
                <div className="login_more_section">
                  <a
                    href="#;"
                    id="qnaLoginBtn"
                    className="login_more_link"
                    onClick={e => {
                      e.preventDefault();
                      setIsQnaPopup(true);
                    }}
                  >
                    문의하기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="login_info">
            <div className="login_info_item">
              <img src="/assets/images/icon/ico-login-info01.png" alt="Q&A" className="login_info_img" />
              {
                hasNoneLoginFaqCategory && (
                  <>
                    <div className="login_info_title">{String(noneLoginFaqCategory?.[0]?.cateNm || '')}</div>
                  </>
                )
              }
              {
                hasNoneLoginFaqCategory && noneLoginFaqData?.length > 0 && (
                  <>
                    <div className="login_info_con">
                      {
                        noneLoginFaqData
                          .filter((item) => item.cateId === noneLoginFaqCategory?.[0]?.cateId)
                          .map((noneLoginFaqItem, index) => (
                            <div key={index} className="login_info_qna">
                              <span className="text">{getHtmlFormatByEscapeHtmlData(noneLoginFaqItem.question)}</span>
                              <a href="#;" className="link" onClick={() => onClickNonFaqItemAnswer(noneLoginFaqItem)}>
                                답변 보기
                              </a>
                            </div>
                          ))
                      }
                    </div>
                  </>
                )
              }
            </div>
            <div className="login_info_item">
              <img src="/assets/images/icon/ico-login-info03.png" alt="password" className="login_info_img" />
              <div className="login_info_title">비밀번호 찾기</div>
              <div className="login_info_con">
                <div className="login_info_txt">
                  비밀번호 찾기는 LG LIFE에 등록된 본인의 이메일 주소로 임시비밀번호를 발송해 드립니다.
                  <br />
                  <br />
                  LG LIFE에서 수신되는 이메일은 각사의 이메일시스템에 따라 <span className="is-em">약간의 시간</span>이
                  소요 될 수 있습니다.
                </div>
              </div>
            </div>
          </div>
          <QnaPopup isOpen={isQnaPopup} onClose={() => setIsQnaPopup(false)} />
          {
            showNoneLoginFaqPopup && (
              <NoneLoginFaqPopup
                popupTitle={selectedNoneLoginFaqInfo?.question as string}
                popupContent={selectedNoneLoginFaqInfo?.reply as string}
                hasHeaderTitle={false}
                onClose={() => setShowNoneLoginFaqPopup(false)}
              />
            )
          }
        </div>
      </div>
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </div>
  );
};

export default Login;
