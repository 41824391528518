import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { CategoryType } from '../types/enum/CategoryType';

const useNoneLoginFaq = () => {
  const { data: noneLoginFaqCategory } = useSWR<CategoryListResDto>([endpoints.common.category, CategoryType.NONE_LOGIN_FAQ], () =>
    fetcher.common.getCategory({ cateType: CategoryType.NONE_LOGIN_FAQ }).then(res => {
      return res;
    })
  );

  const { data: noneLoginFaqData } = useSWR<FaqNoneLoginListResDto>(endpoints.faq.faq, () =>
    fetcher.faq.getNoneLoginFaq().then(res => {
      return res;
    })
  );

  return {
    noneLoginFaqCategory: (noneLoginFaqCategory?.categoryList?.sort((a, b) => a?.orderNum - b?.orderNum) || []) as CategoryResDto[],
    noneLoginFaqData: (noneLoginFaqData?.faqList?.sort((a, b) => a?.orderNum - b?.orderNum) || []) as FaqResDto[],
  };
};

export default useNoneLoginFaq;
