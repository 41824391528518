import topEatsBoards from './fetcher/topEatsBoards';

export { SERVER_URL } from 'commons/constants';

export const endpoints = {
  auth: {
    cic_user_info: '/api/fo/auth/cic-user-info',
    confirm_member: '/api/fo/auth/confirm-member',
    find_password: '/api/fo/auth/find-password',
    sign_in: '/api/fo/auth/sign-in',
    sign_out: '/api/fo/auth/sign-out',
    sign_up: '/api/fo/auth/sign-up',
    ssocheck: '/api/fo/auth/sso/check',
    tokenRefresh: '/api/fo/auth/token/refresh',
    mobile_check: '/api/fo/auth/mobile-check',
    autoLoginEmail_check: '/api/fo/auth/checkValidAutoLoginEmail',
    autoLoginToLGAcademy: '/api/fo/auth/auto-login-to-lg-academy',
    autoLoginToLGAwards: '/api/fo/auth/auto-login-to-lg-awards',
    /** LG BP Library 자동 로그인 - Home 으로 연결 */
    autoLoginToLGBPLibraryHome: '/api/fo/auth/auto-login-to-lg-bp-library-to-bp-home',
  },
  epPanel: {
    banners: '/api/fo/ep/banners',
    heroes: '/api/fo/ep/heroes',
    displayGroupHeroList: '/api/fo/ep/display-group-hero-list',
    displayEventList: '/api/fo/ep/display-event-list',
    displayBoardList: '/api/fo/ep/display-community-list',
    news: '/api/fo/ep/news',
    popularContents: '/api/fo/ep/popular-contents',
    abInitial: '/api/fo/ep/abtest/initial',
    abDuplicate: '/api/fo/ep/abtest/duplicate',
    getGroupHeroLinkDetailInfo: '/api/fo/ep/group-hero-link-detail-info',
  },
  main: {
    ad: '/api/fo/main/ads',
    banner: '/api/fo/main/banner',
    board: '/api/fo/main/boards',
    hero: '/api/fo/main/heroes',
    news: '/api/fo/main/news',
    playlist: '/api/fo/main/editors-plan',
    popularContent: '/api/fo/main/popular-contents',
    recommend: '/api/fo/main/recommends',
    section: '/api/fo/main/sections',
    sns: '/api/fo/main/sns',
  },
  common: {
    user: '/api/fo/common',
    category: '/api/fo/common/category',
    userInfos: '/api/fo/common/user-infos',
    cic: '/api/fo/common/cic',
    groupCompanies: '/api/fo/common/group-companies',
    groupDomains: '/api/fo/common/group-domains',
    job: '/api/fo/common/job',
    popularityKeyword: '/api/fo/common/popularity-keyword',
    position: '/api/fo/common/position',
    profile: '/api/fo/common/profile',
    read: '/api/fo/common/read',
    share: '/api/fo/common/share',
    userRank: '/api/fo/common/user-rank',
    introAreas: '/api/fo/common/intro-areas',
    logging: '/api/fo/common/logging',
    /** NEW 뱃지 여부 */
    displayNewBadgeStatus: '/api/fo/common/display-new-badge-status',
  },
  popup: {
    displayOnboarding: '/api/fo/popup/display-onboarding',
    readOnboarding: '/api/fo/popup/read-onboarding',
  },
  comment: {
    comment: '/api/fo/comments',
    postComment: '/api/fo/comments',
    putComment: '/api/fo/comments',
    deleteComment: '/api/fo/comments',
    replyComment: '/api/fo/comments/{commentId}',
    like: '/api/fo/comments/like',
  },
  notice: {
    notice: '/api/fo/notice/list',
    noticeRead: '/api/fo/notice',
    /** 읽지 않은 알림 개수 */
    newNoticeCount: '/api/fo/notice/new-count',
    /** 상세 알림 유형에 따라 읽지 않은 알림 목록 조회 */
    nonReadNoticeList: '/api/fo/notice/non-read-notice/list',
  },
  videos: {
    videos: '/api/fo/videos',
    videoDetail: '/api/fo/videos/{videoId}',
    basicTags: '/api/fo/videos/basic-tags',
  },
  /** 자유 게시판 */
  boards: {
    board: '/api/fo/board',
    boardWrite: '/api/fo/board',
    boardDetail: '/api/fo/board/{boardId}',
    popularContent: '/api/fo/board/popular-contents',
    boardUpdate: '/api/fo/board/{boardId}',
    boardDelete: '/api/fo/board/{boardId}',
    boardLike: '/api/fo/board/like',
    boardShare: '/api/fo/board/share',
    /** 홍보 게시물 제한 일일 잔여 건수 */
    allowedAdvertisementUploadCount: '/api/fo/board/allowed-advertisement-upload-count',
  },
  /** 맛집 게시판 */
  topEatsBoards: {
    topEatsBoard: '/api/fo/topEatsBoard',
    topEatsBoardWrite: '/api/fo/topEatsBoard',
    topEatsBoardDetail: '/api/fo/topEatsBoard/{boardId}',
    topEatsBoardUpdate: '/api/fo/topEatsBoard/{boardId}',
    topEatsBoardDelete: '/api/fo/topEatsBoard/{boardId}',
    topEatsBoardLike: '/api/fo/topEatsBoard/like',
    topEatsBoardShare: '/api/fo/topEatsBoard/share',
  },
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 칭찬 게시판 */
  // mentionsBoards: {
  //   mentionsBoard: '/api/fo/mentions-board',
  //   mentionsBoardWrite: '/api/fo/mentions-board',
  //   mentionsBoardDetail: '/api/fo/mentions-board/{boardId}',
  //   mentionsBoardUpdate: '/api/fo/mentions-board/{boardId}',
  //   mentionsBoardDelete: '/api/fo/mentions-board/{boardId}',
  //   mentionsBoardLike: '/api/fo/mentions-board/like',
  //   mentionsBoardShare: '/api/fo/mentions-board/share',
  // },
  /** 회사 속 직무 게시판 */
  departmentBoard: {
    departmentBoard: '/api/fo/department-board',
    departmentBoardWrite: '/api/fo/department-board',
    departmentBoardDetail: '/api/fo/department-board/{boardId}',
    departmentBoardUpdate: '/api/fo/department-board/{boardId}',
    departmentBoardDelete: '/api/fo/department-board/{boardId}',
    departmentBoardLike: '/api/fo/department-board/like',
    departmentBoardShare: '/api/fo/department-board/share',
  },
  /** 회사 밖 소모임 게시판 */
  societyBoard: {
    societyBoard: '/api/fo/society-board',
    societyBoardWrite: '/api/fo/society-board',
    societyBoardDetail: '/api/fo/society-board/{boardId}',
    societyBoardUpdate: '/api/fo/society-board/{boardId}',
    societyBoardDelete: '/api/fo/society-board/{boardId}',
    societyBoardLike: '/api/fo/society-board/like',
    societyBoardShare: '/api/fo/society-board/share',
  },
  /** LG LIFE 공지 게시판 */
  announcementBoard: {
    announcementBoard: '/api/fo/announcement-board',
    announcementBoardDetail: '/api/fo/announcement-board/{boardId}',
    announcementBoardLike: '/api/fo/announcement-board/like',
    announcementBoardShare: '/api/fo/announcement-board/share',
  },
  /** 게시판 임시 저장 */
  tempBoards: {
    tempBoard: '/api/fo/temp-board',
    tempBoardWrite: '/api/fo/temp-board',
    tempBoardDetail: '/api/fo/temp-board/detail',
    tempBoardDelete: '/api/fo/temp-board/remove',
  },
  media: {
    media: '/api/fo/medias',
    detail: '/api/fo/medias/{mediaId}',
    basicTags: '/api/fo/medias/basic-tags',
    bookmark: '/api/fo/medias/bookmark',
    continue: '/api/fo/medias/continue',
    download: '/api/fo/medias/download-apply',
    like: '/api/fo/medias/like',
    playlist: '/api/fo/medias/playlist',
    related: '/api/fo/medias/related',
    share: '/api/fo/medias/share',
    esg: '/api/fo/medias/esg',
    /** 명예의 전당 연관 컨텐츠 */
    lgAwardsRelationContents: '/api/fo/medias/lg-awards/relation-contents-list',
    /** 명예의 전당 연도별 조회 건수 */
    addLgAwardsPageViewCount: '/api/fo/medias/lg-awards/addLgAwardsPageViewCount',
    /** 미디어 간단 설문 사용자 답변 */
    addMediaSimpleSurveyAnswer: '/api/fo/medias/add-media-simple-survey-answer',
  },
  event: {
    event: '/api/fo/events',
    detail: '/api/fo/events/{eventId}',
    like: '/api/fo/events/like',
    share: '/api/fo/events/share',
    survey: '/api/fo/events/survey',
    // 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
    // simple_survey: '/api/fo/events/simple-survey',
    quiz: '/api/fo/events/monthly-quiz',
    event_apply: '/api/fo/events/event/apply',
  },
  /**
   * FAQ
   */
  faq: {
    /** 로그인 FAQ */
    faq: '/api/fo/faq/login-faq-list',
    /** 비 로그인 FAQ */
    noneLoginFaq: '/api/fo/faq/none-login-faq-list',
  },
  qna: {
    qna: '/api/fo/qna',
    qnaDetail: '/api/fo/qna/{qnaId}',
    qnaNonLogin: '/api/fo/qna/non-login',
  },
  recommends: {
    recommends: '/api/fo/recommends',
    keywords: '/api/fo/recommends/keywords',
  },
  story70: {
    story: '/api/fo/story',
    storyDetail: '/api/fo/story/{storyId}',
    like: '/api/fo/story/like',
    share: '/api/fo/story/share',
  },
  myPage: {
    boards: '/api/fo/my-page/boards',
    topEatsBoards: '/api/fo/my-page/topEatsBoards',
    mentionsBoards: '/api/fo/my-page/mentionsBoards',
    departmentBoards: '/api/fo/my-page/departmentBoards',
    societyBoards: '/api/fo/my-page/societyBoards',
    // 사용 하지 않는 자유 게시판 좋아요 hook 제거 - 2024.06.27
    // boardsLikes: '/api/fo/my-page/boards/likes',
    bookmarks: '/api/fo/my-page/bookmarks',
    briefInfo: '/api/fo/my-page/brief-info',
    // 사용 하지 않는 이벤트 좋아요 hook 제거 - 2024.06.27
    // eventsLikes: '/api/fo/my-page/events/likes',
    info: '/api/fo/my-page/info',
    interestKeyword: '/api/fo/my-page/interest-keyword',
    // 사용 하지 않는 콘텐츠 좋아요 hook 제거 - 2024.06.27
    // mediasLikes: '/api/fo/my-page/medias/likes',
    password: '/api/fo/my-page/password',
    recent: '/api/fo/my-page/recent',
    currentlyContents: '/api/fo/my-page/currentlyContents',
  },
  mobile: {
    verify: '/api/fo/mobile/token/verify',
  },
  attachFile: {
    download: '/api/attach-files',
  },
  search: {
    search: '/api/fo/search',
    autoComplete: '/api/fo/search/auto-complete',
  },
  celebration75: {
    celebration: '/api/fo/celebration',
    write: '/api/fo/celebration',
    detail: '/api/fo/celebration/{messageId}',
    modify: '/api/fo/celebration/{messageId}',
    delete: '/api/fo/celebration/{messageId}',
  },
  series: {
    series: '/api/fo/medias/series-classifications',
    detail: '/api/fo/medias/series-classification/{sclasId}',
  },
  /** 임원 인사 */
  executiveAppointment: {
    /** 노출 중인 임원 인사 연도 정보 조회 */
    getExecutiveAppointmentYearDisplayInfo: '/api/fo/personnel/appointment-display-info',
    /** 노출 중인 임원 인사 (통합) 상세 정보 조회 */
    getExecutiveAppointmentIntegratedDetail: '/api/fo/personnel/appointment-integrate-detail/{yearId}',
  },

  // users: '/api/unknown',
  // myInfo: '/api/users/2',
  // login: '/api/login',
};

export const konanEndPoints = {
  videos: '/api/users',
};
