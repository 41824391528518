import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface BannerRequest {
  isLogin: boolean;
}

export interface HeroesRequest {}

export interface EpDisplayGroupHeroListRequest {
  isLogin: boolean;
}

export interface NewsRequest {}

export interface PopularContentsRequest {}

export default {
  getBanners: (params: BannerRequest): Promise<EpBannerListResDto> =>
    request(
      {
        url: endpoints.epPanel.banners,
        method: 'get',
      },
      {
        noAuth: !params.isLogin,
      }
    ),
  getHeroes: (params: HeroesRequest): Promise<any> =>
    request({
      url: endpoints.epPanel.heroes,
      method: 'get',
      params,
    }),
  getDisplayGroupHeroList: (params: EpDisplayGroupHeroListRequest): Promise<EpGroupHeroListResDto> =>
    request(
      {
        url: endpoints.epPanel.displayGroupHeroList,
        method: 'get',
      },
      {
        noAuth: !params.isLogin,
      }
    ),
  /** EP 계열사 배너 링크 상세 정보 */
  getGroupHeroLinkDetailInfo: (params: EpGroupHeroLinkDetailReqDto): Promise<EpGroupHeroLinkDetailResDto> =>
    request({
      url: endpoints.epPanel.getGroupHeroLinkDetailInfo,
      method: 'post',
      data: params,
    }),
  getDisplayEventList: (page: number): Promise<EpEventDisplayListResDto> =>
    request({
      url: endpoints.epPanel.displayEventList,
      method: 'get',
      params: {
        page: page,
        size: 10,
        sort: 'orderNum,asc',
      },
    }),
  getDisplayBoardList: (page: number): Promise<EpBoardDisplayListResDto> =>
    request({
      url: endpoints.epPanel.displayBoardList,
      method: 'get',
      params: {
        page: page,
        size: 10,
        sort: 'orderNum,asc',
      },
    }),
  getNews: (params: NewsRequest): Promise<any> =>
    request({
      url: endpoints.epPanel.news,
      method: 'get',
      params,
    }),
  getPopularContents: (params: PopularContentsRequest): Promise<any> =>
    request({
      url: endpoints.epPanel.popularContents,
      method: 'get',
      params,
    }),
  postABTestInitial: (data: AddAbTestInfoReqDto): Promise<any> =>
    request({
      url: endpoints.epPanel.abInitial,
      method: 'post',
      data,
    }),
  postABTestDuplicate: (data: AddAbTestInfoReqDto): Promise<any> =>
    request({
      url: endpoints.epPanel.abDuplicate,
      method: 'post',
      data,
    }),
};
