import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface Props {
  active?: boolean;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GradeTooltip = ({ active = true, setActive }: Props, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
  return (
    <div className={classNames('grade_tooltip', { 'is-active': active })} ref={ref}>
      <div className="popup_dim is-mobile"></div>
      <div className="tooltip_inner">
        <div className="tooltip_header">
          <strong className="tooltip_title">등급</strong>
        </div>
        <div className="tooltip_cont">
          <div className="grade_desc">
            적극적인 활동으로 LG LIFE 회원등급을 높여보세요!
            <br />
            댓글, 좋아요, 공유하기, 게시글 작성, 이벤트 참여 등의 활동을 통해 활동점수를 얻을 수 있습니다.
          </div>
          <div className="grade_grid">
            <table>
              <colgroup>
                <col style={{ width: '106px' }} />
                <col style={{ width: '106px' }} />
                <col style={{ width: '106px' }} />
                <col style={{ width: '106px' }} />
                <col style={{ width: '106px' }} />
              </colgroup>
              <thead>
                <tr>
                  <th className="grade_grid_head">브론즈</th>
                  <th className="grade_grid_head">실버</th>
                  <th className="grade_grid_head">골드</th>
                  <th className="grade_grid_head">다이아몬드</th>
                  <th className="grade_grid_head">크라운</th>
                </tr>
              </thead>
              <tbody>
                <tr className="grade_list_item">
                  {/* <!-- 등급 아이콘 cell에는 is-grade 추가--> */}
                  <td className="grade_list_cell is-grade">
                    <img src="/assets/images/icon/ico-grade-bronze.png" alt="" />
                  </td>
                  <td className="grade_list_cell is-grade">
                    <img src="/assets/images/icon/ico-grade-silver.png" alt="" />
                  </td>
                  <td className="grade_list_cell is-grade">
                    <img src="/assets/images/icon/ico-grade-gold.png" alt="" />
                  </td>
                  <td className="grade_list_cell is-grade">
                    <img src="/assets/images/icon/ico-grade-dia.png" alt="" />
                  </td>
                  <td className="grade_list_cell is-grade">
                    <img src="/assets/images/icon/ico-grade-crown.png" alt="" />
                  </td>
                </tr>
                <tr className="grade_list_item">
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img src="/assets/images/icon/ico-grade-img-bronze.png" className="profile_grade" alt="" />
                      <img src="/assets/images/icon/profile_sample.png" className="profile_img" alt="" />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img src="/assets/images/icon/ico-grade-img-silver.png" className="profile_grade" alt="" />
                      <img src="/assets/images/icon/profile_sample.png" className="profile_img" alt="" />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img src="/assets/images/icon/ico-grade-img-gold.png" className="profile_grade" alt="" />
                      <img src="/assets/images/icon/profile_sample.png" className="profile_img" alt="" />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile is-dia">
                      <img src="/assets/images/icon/ico-grade-img-dia.png" className="profile_grade" alt="" />
                      <img src="/assets/images/icon/profile_sample.png" className="profile_img" alt="" />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile is-crown">
                      <img src="/assets/images/icon/ico-grade-img-crown.png" className="profile_grade" alt="" />
                      <img src="/assets/images/icon/profile_sample.png" className="profile_img" alt="" />
                    </div>
                  </td>
                </tr>
                <tr className="grade_list_item">
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img
                        src="/assets/images/icon/ico-grade-sample-bronze.png"
                        className="profile_grade"
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img
                        src="/assets/images/icon/ico-grade-sample-silver.png"
                        className="profile_grade"
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile">
                      <img
                        src="/assets/images/icon/ico-grade-sample-gold.png"
                        className="profile_grade"
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile is-dia">
                      <img src="/assets/images/icon/ico-grade-sample-dia.png" className="profile_grade" alt="" />
                    </div>
                  </td>
                  <td className="grade_list_cell">
                    <div className="profile is-crown">
                      <img
                        src="/assets/images/icon/ico-grade-sample-crown.png"
                        className="profile_grade"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grade_confirm is-mobile">
            <button
              type="button"
              className="popup_confirm"
              onClick={() => {
                if (setActive) {
                  setActive(false);
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
        <button
          type="button"
          className="tooltip_close ico_close"
          onClick={() => {
            if (setActive) {
              setActive(false);
            }
          }}
        >
          <div style={{ display: 'none' }}>close</div>
        </button>
      </div>
    </div>
  );
};
export default forwardRef(GradeTooltip);
