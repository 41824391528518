import { useMemo, useState } from 'react';
import { matchPath } from 'react-router-dom';
import useSWR from 'swr';
import paths from 'commons/paths';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

interface Props {
  contentsCnt?: string;
}

const useMyPage = ({ contentsCnt }: Props) => {
  const { info } = useMyInfo();
  const [page, setPage] = useState<string>('1');

  const isMainPage = useMemo(
    () => matchPath(location.pathname, { path: paths.getPath('mypage'), exact: true }),
    [location.pathname]
  );
  const isEditPage = useMemo(() => matchPath(location.pathname, paths.getPath('edit')), [location.pathname]);
  const isRecentPage = useMemo(() => matchPath(location.pathname, paths.getPath('recent')), [location.pathname]);
  const isBookmarkPage = useMemo(() => matchPath(location.pathname, paths.getPath('bookmark')), [location.pathname]);
  // 사용 하지 않는 마이 페이지 좋아요 관련 제거 - 2024.06.27
  // const isLikePage = useMemo(() => matchPath(location.pathname, paths.getPath('like')), [location.pathname]);
  /** 마이 페이지 - 자유 게시판 여부 */
  const isBoardPage = useMemo(() => matchPath(location.pathname, paths.getPath('myboard')), [location.pathname]);
  /** 마이 페이지 - 회사 옆 맛집 게시판 여부 */
  const isTopEatsBoardPage = useMemo(
    () => matchPath(location.pathname, paths.getPath('myTopEatsBoard')),
    [location.pathname]
  );
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 마이 페이지 - 칭찬합니다 게시판 여부 */
  // const isMentionsBoardPage = useMemo(
  //   () => matchPath(location.pathname, paths.getPath('myMentionsBoard')),
  //   [location.pathname]
  // );
  /** 마이 페이지 - 회사 속 직무 게시판 여부 */
  const isDepartmentBoardPage = useMemo(
    () => matchPath(location.pathname, paths.getPath('myDepartmentBoard')),
    [location.pathname]
  );
  /** 마이 페이지 - 회사 밖 소모임 게시판 여부 */
  const isSocietyBoardPage = useMemo(
    () => matchPath(location.pathname, paths.getPath('mySocietyBoard')),
    [location.pathname]
  );

  const contentsCount = useMemo(() => (contentsCnt ? contentsCnt : isMainPage ? '4' : '16'), [location.pathname]);

  /** 자유 게시판 */
  const { data: boards, isValidating: boardsIsValidating } = useSWR<any>(
    info && (isMainPage || isBoardPage) ? [endpoints.myPage.boards, page] : null,
    () =>
      fetcher.myPage.getBoards({ page: page }).then((res: BoardListResDto) => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
          return null;
        }
      })
  );

  /** 회사 옆 맛집 게시판 */
  const { data: topEatsBoards, isValidating: topEatsBoardsIsValidating } = useSWR<any>(
    info && (isMainPage || isTopEatsBoardPage) ? [endpoints.myPage.topEatsBoards, page] : null,
    () =>
      fetcher.myPage.getTopEatsBoards({ page: page }).then((res: TopEatsBoardListResDto) => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
          return null;
        }
      })
  );

  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 칭찬합니다 게시판 */
  // const { data: mentionsBoards, isValidating: mentionsBoardsIsValidating } = useSWR<any>(
  //   info && (isMainPage || isMentionsBoardPage) ? [endpoints.myPage.mentionsBoards, page] : null,
  //   () =>
  //     fetcher.myPage.getMentionsBoards({ page: page }).then((res: MentionsBoardListResDto) => {
  //       if (res.resultCode === 200) {
  //         const { resultCode, resultMessage, ...data } = res;
  //         return data;
  //       } else {
  //         console.log(res.resultMessage);
  //         return null;
  //       }
  //     })
  // );

  /** 회사 속 직무 게시판 */
  const { data: departmentBoards, isValidating: departmentBoardsIsValidating } = useSWR<any>(
    info && (isMainPage || isDepartmentBoardPage) ? [endpoints.myPage.departmentBoards, page] : null,
    () =>
      fetcher.myPage.getDepartmentBoards({ page: page }).then((res: DepartmentBoardListResDto) => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
          return null;
        }
      })
  );

  /** 회사 밖 소모임 게시판 */
  const { data: societyBoards, isValidating: societyBoardsIsValidating } = useSWR<SocietyBoardListResDto>(
    info && (isMainPage || isSocietyBoardPage) ? [endpoints.myPage.societyBoards, page] : null,
    () =>
      fetcher.myPage.getSocietyBoards({ page: page }).then((res: SocietyBoardListResDto) => {
        if (res.resultCode !== 200) {
          console.log(res.resultMessage);
        }
        return res;
      })
  );

  // 사용 하지 않는 게시판 좋아요 hook 제거 - 2024.06.27
  // const { data: boardsLikes } = useSWR<any>(info && isLikePage ? endpoints.myPage.boardsLikes : null, () =>
  //   fetcher.myPage.getBoardsLikes().then((res: BoardLikeListResDto) => {
  //     if (res.resultCode === 200) {
  //       const { resultCode, resultMessage, ...data } = res;
  //       return data;
  //     } else {
  //       console.log(res.resultMessage);
  //       return null;
  //     }
  //   })
  // );

  const { data: bookmarks, isValidating: bookmarksIsValidating } = useSWR<any>(
    info && (isMainPage || isBookmarkPage) ? [endpoints.myPage.bookmarks, contentsCount] : null,
    () =>
      fetcher.myPage.getBookMarks({ contentsCnt: contentsCount }).then((res: MediaBookmarkListResDto) => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
          return null;
        }
      })
  );

  const { data: briefInfo } = useSWR<any>(info && !isEditPage ? endpoints.myPage.briefInfo : null, () =>
    fetcher.myPage.getBriefInfo().then((res: UserBriefInfoResDto) => {
      if (res.resultCode === 200) {
        const { resultCode, resultMessage, ...data } = res;
        return data;
      } else {
        console.log(res.resultMessage);
        return null;
      }
    })
  );

  // 사용 하지 않는 마이 페이지 이벤트 좋아요 hook 제거 - 2024.06.27
  // const { data: eventsLikes } = useSWR<any>(info && isLikePage ? endpoints.myPage.eventsLikes : null, () =>
  //   fetcher.myPage.getEventsLikes().then((res: EventLikeListResDto) => {
  //     if (res.resultCode === 200) {
  //       const { resultCode, resultMessage, ...data } = res;
  //       return data;
  //     } else {
  //       console.log(res.resultMessage);
  //       return null;
  //     }
  //   })
  // );

  const { data: myInfo } = useSWR<any>(info && isEditPage ? endpoints.myPage.info : null, () =>
    fetcher.myPage.getInfo().then((res: UserDetailInfoResDto) => {
      if (res.resultCode === 200) {
        const { resultCode, resultMessage, ...data } = res;
        return data;
      } else {
        console.log(res.resultMessage);
        return null;
      }
    })
  );

  const putInfo = (params: FormData) => {
    return fetcher.myPage.putInfo(params);
  };

  const putInterestKeyword = (intrKeywordList: Array<string>) => {
    return fetcher.myPage.putInterestKeyword({
      intrKeywordList: intrKeywordList,
    });
  };

  // 사용 하지 않는 마이 페이지 콘텐츠 좋아요 hook 제거 - 2024.06.27
  // const { data: mediasLikes, isValidating: likesIsValidating } = useSWR<any>(
  //   info && (isMainPage || isLikePage) ? [endpoints.myPage.mediasLikes, contentsCount] : null,
  //   () =>
  //     fetcher.myPage.getMediasLikes({ contentsCnt: contentsCount }).then((res: MediaLikeListResDto) => {
  //       if (res.resultCode === 200) {
  //         const { resultCode, resultMessage, ...data } = res;
  //         return data;
  //       } else {
  //         console.log(res.resultMessage);
  //         return null;
  //       }
  //     })
  // );

  const putPassword = (params: UserPasswordReqDto) => {
    return fetcher.myPage.putPassword(params);
  };

  const { data: recent, isValidating: recentIsValidating } = useSWR<any>(
    info && (isMainPage || isRecentPage) ? [endpoints.myPage.recent, contentsCount] : null,
    () =>
      fetcher.myPage.getRecent({ contentsCnt: contentsCount }).then(res => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
          return null;
        }
      })
  );

  return {
    /* 자유 게시판 */
    boards: boards as BoardListResDto,
    boardsIsValidating,
    /* 회사 옆 맛집 */
    topEatsBoards: topEatsBoards as TopEatsBoardListResDto,
    topEatsBoardsIsValidating,
    // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
    /* 칭찬합니다 게시판 */
    // mentionsBoards: mentionsBoards as MentionsBoardListResDto,
    // mentionsBoardsIsValidating,
    /* 회사 속 직무 게시판 */
    departmentBoards: departmentBoards as DepartmentBoardListResDto,
    departmentBoardsIsValidating,
    /* 회사 밖 모임 게시판 */
    societyBoards: societyBoards as SocietyBoardListResDto,
    societyBoardsIsValidating,
    // 사용 하지 않는 자유 게시판 좋아요 hook 제거 - 2024.06.27
    // boardsLikes,
    bookmarks,
    bookmarksIsValidating,
    briefInfo,
    // 사용 하지 않는 마이 페이지 이벤트 좋아요 hook 제거 - 2024.06.27
    // eventsLikes,
    // 사용 하지 않는 마이 페이지 콘텐츠 좋아요 hook 제거 - 2024.06.27
    // mediasLikes,
    // 사용 하지 않는 마이 페이지 콘텐츠 좋아요 hook 제거 - 2024.06.27
    // likesIsValidating,
    recent,
    recentIsValidating,
    myInfo,
    putInfo,
    putInterestKeyword,
    putPassword,
    page,
    setPage,
  };
};

export default useMyPage;
