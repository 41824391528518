import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

interface DetailScriptProps {
  closeScript: () => void;
  scriptTitle?: string;
  scriptDesc?: string;
}

const DetailScript = (props: DetailScriptProps) => {
  return (
    <>
      <div className="detail_script_wrap">
        <div className="detail_script_top">
          <span>원고 보기</span>
          <button type="button" className="detail_script_close ico_close" onClick={props.closeScript}></button>
        </div>
        <div className="detail_script_con custom_scroll">
          <div className="detail_script_inner">
            <div className="detail_script_title">{props.scriptTitle}</div>
            <InnerHTML className="detail_script_desc" html={props.scriptDesc || ''} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailScript;
