export enum CategoryType {
  /** 자유 게시판 */
  BOARD = 'BOARD',
  /** 자유 게시판 서브 카테고리 */
  SUB = 'SUB',
  /** 회사 옆 맛집 게시판 - 근무지 정보 */
  REGION = 'REGION',
  /** 회사 옆 맛집 게시판 - 서브 카테고리 */
  TOP_EATS_BOARD_SUB = 'TOP_EATS_BOARD_SUB',
  /** 1대1 문의 */
  QNA = 'QNA',
  /** FAQ */
  FAQ = 'FAQ',
  /** 비 로그인 FAQ */
  NONE_LOGIN_FAQ = 'NONE_LOGIN_FAQ',
  /** 콘텐츠 */
  CONTENTS = 'CONTENTS',
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  // /** 칭찬 게시판 - 서브 카테고리 **/
  // MENTIONS = 'MENTIONS',
  // /** 칭찬 게시판 - 서브 카테고리 */
  // MENTIONS_SUB = 'MENTIONS_SUB',
  /** 회사 속 직무 - 메인 카테고리 */
  DEPARTMENT_BOARD = 'DEPARTMENT_BOARD',
  /** 회사 속 직무 - 서브 카테고리(머릿말) */
  DEPARTMENT_BOARD_SUB = 'DEPARTMENT_BOARD_SUB',
  /** 회사 밖 소모임 - 메인 카테고리 */
  SOCIETY_BOARD = 'SOCIETY_BOARD',
  /** 회사 밖 소모임 - 서브 카테고리 */
  SOCIETY_BOARD_SUB = 'SOCIETY_BOARD_SUB',
  /** LG LIFE 공지 게시판 - 메인 카테고리 */
  ANNOUNCEMENT_BOARD = 'ANNOUNCEMENT_BOARD',
  /** LG LIFE 공지 게시판 - 서브 카테고리 (미사용) */
  ANNOUNCEMENT_BOARD_SUB = 'ANNOUNCEMENT_BOARD_SUB',
}
