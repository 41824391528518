import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';
import { PagingReqDto } from '../../types';

export interface AnnouncementBoardListRequest extends PagingReqDto {
  /** 카테고리 목록 */
  cateList?: string;
  /** 서브 카테고리 목록 */
  subCateList?: string;
  /** 게시판 유형 */
  boardType?: string;
  /** 상단 통함 검색어 */
  topSearchWord?: string;
  /** 게시판 내 검색어 */
  searchWordIn?: string;
}

export interface AnnouncementBoardWriteReq {
  attachFiles: File[];
  cateId: string;
  contents: string;
  contentsText: string;
  subCateId: string;
  boardType: string;
  tags: Array<string>;
  title: string;
}

export interface AnnouncementBoardUpdateReq extends AnnouncementBoardDetailReqDto {
  uploadedFiles: AttachFileResDto[];
}

export interface AnnouncementBoardLikeReq {
  boardId: string;
}

export interface AnnouncementBoardDeleteReq {
  boardId: string;
}

export default {
  /** 게시글 목록 조회 */
  getAnnouncementBoardList: (params: AnnouncementBoardListRequest): Promise<AnnouncementBoardListResDto> =>
    request({
      url: endpoints.announcementBoard.announcementBoard,
      method: 'get',
      params,
    }),
  /** 게시글 상세 내용 조회 */
  getAnnouncementBoardDetail: (url: string): Promise<AnnouncementBoardDetailResDto> =>
    request({
      url,
      method: 'get',
    }),
  /** 게시글 작성 */
  postAnnouncementBoardWrite: (params: AnnouncementBoardWriteReq): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'ANNOUNCEMENT_BOARD',
    } as DepartmentBoardDetailReqDto;

    if (!!params.attachFiles?.length) {
      for (let i = 0; i < params.attachFiles.length; i++) {
        postParams['attachFiles[' + i + '].attachFile'] = params.attachFiles[i];
        postParams['attachFiles[' + i + '].attachFileId'] = '0';
      }
    }

    delete postParams.attachFiles;

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: endpoints.announcementBoard.announcementBoard,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  /** 게시글 수정 */
  putAnnouncementBoardUpdate: (url: string, params: AnnouncementBoardUpdateReq): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'DEPARTMENT_BOARD',
    };

    const uploadedFilesLength = postParams.uploadedFiles.length;

    for (let i = 0; i < uploadedFilesLength; i++) {
      postParams['attachFiles[' + i + '].attachFileId'] = postParams.uploadedFiles[i].fileId;
    }

    if (!!postParams.attachFiles?.length) {
      for (let i = 0; i < postParams.attachFiles.length; i++) {
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFile'] = postParams.attachFiles[i];
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFileId'] = '0';
      }
    }

    delete postParams.attachFiles;

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  /** 게시글 좋아요 */
  postAnnouncementBoardLike: (params: AnnouncementBoardLikeReq): Promise<BaseResDto> =>
    request({
      url: endpoints.announcementBoard.announcementBoardLike,
      method: 'post',
      params,
    }),
  /** 게시글 삭제 */
  deleteAnnouncementBoard: (url: string, params?: AnnouncementBoardDeleteReq): Promise<BaseResDto> =>
    request({
      url,
      method: 'delete',
      params,
    }),
  /** 게시글 공유 */
  shareAnnouncementBoard: (params: ShareReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.announcementBoard.announcementBoardShare,
      method: 'post',
      data: params,
    }),
};
