import React from 'react';

interface ShareCancelPopupProps {
  closeSharePopup: () => void;
  closeCancelPopup: () => void;
}

const ShareCancelPopup = (props: ShareCancelPopupProps) => {
  return (
    <>
      <div className="confirm_popup share_close_popup is-active">
        <div className="popup_dim"></div>
        <div className="popup_inner">
          <p className="confirm_popup_text">
            이메일 공유하기를 취소하시겠습니까?
            <br />
            취소시 작성중이던 내용은 모두 삭제됩니다.
          </p>
          <ul className="confirm_button_list">
            <li className="confirm_button_item">
              <button
                type="button"
                className="confirm_popup_button is-dim popup_button_cancel"
                onClick={props.closeCancelPopup}
              >
                취소
              </button>
            </li>
            <li className="confirm_button_item">
              <button
                type="button"
                className="confirm_popup_button popup_button_confirm share_close_confirm"
                onClick={props.closeSharePopup}
              >
                확인
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ShareCancelPopup;
