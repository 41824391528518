import { lazy, LazyExoticComponent } from 'react';
import { matchPath } from 'react-router-dom';

export interface Path {
  title: string;
  key: string;
  page_name?: string;
  component?: LazyExoticComponent<any>;
  children?: Array<Path>;
  private?: boolean;
}

const paths: Path[] = [
  /** 홈 */
  {
    title: 'home',
    key: '/',
    page_name: 'Home/Main',
    component: lazy(() => import(`components/pages/Home`)),
    private: true,
  },
  /** 유저 - 로그인 */
  {
    title: 'user',
    key: '/user',
    children: [
      {
        title: 'login',
        key: '/user/login',
        page_name: 'Log-in/로그인하기',
        component: lazy(() => import(`components/pages/Login`)),
      },
      {
        title: 'findpw',
        key: '/user/findpw',
        page_name: 'Log-in/비밀번호 찾기',
        component: lazy(() => import(`components/pages/Login/FindPassword`)),
      },
      {
        title: 'confirmMember',
        key: '/user/confirmMember',
        page_name: 'Log-in/가입여부 확인',
        component: lazy(() => import(`components/pages/Login/ConfirmMember`)),
      },
      {
        title: 'agree',
        key: '/user/agree',
        page_name: 'Log-in/회원약관',
        component: lazy(() => import(`components/pages/Login/Agree`)),
      },
      {
        title: 'signup',
        key: '/user/signup',
        page_name: 'Log-in/신규가입',
        component: lazy(() => import(`components/pages/Login/SignUp`)),
      },
      {
        title: 'signupComplete',
        key: '/user/signupComplete',
        page_name: 'Log-in/가입완료',
        component: lazy(() => import(`components/pages/Login/SignupComplete`)),
      },
      {
        title: 'privacypublic',
        key: '/user/privacypublic',
        page_name: 'PrivacyPublic',
        component: lazy(() => import(`components/pages/Login/PrivacyPublic`)),
      },
    ],
  },
  /** 콘텐츠 */
  {
    title: 'contents',
    key: '/contents',
    children: [
      /** 동영상 */
      {
        title: 'video',
        key: '/contents/video',
        children: [
          {
            title: 'videoList',
            key: '/contents/video/list',
            page_name: 'Video/List',
            component: lazy(() => import(`components/pages/Video`)),
            private: true,
          },
          {
            title: 'videoDetail',
            key: '/contents/video/detail/:mediaId',
            page_name: 'Video/Detail Page',
            component: lazy(() => import(`components/pages/Video/VideoDetail`)),
            private: true,
          },
          {
            title: 'videoDownload',
            key: '/contents/video/download/detail',
            page_name: 'Video/Download Page',
            component: lazy(() => import(`components/pages/Video/VideoDownload`)),
            private: true,
          },
          {
            title: 'videoSeries',
            key: '/contents/video/series/:sclasId',
            page_name: 'Video/Series Page',
            component: lazy(() => import(`components/pages/Series/Video`)),
            private: true,
          },
        ],
      },
      /** 매거진 */
      {
        title: 'card',
        key: '/contents/card',
        children: [
          {
            title: 'cardNewsList',
            key: '/contents/card/list',
            page_name: 'CardNews/List',
            component: lazy(() => import(`components/pages/Magazine/CardNews`)),
            private: true,
          },
          {
            title: 'cardNewsDetail',
            key: '/contents/card/detail/:mediaId',
            page_name: 'CardNews/Detail Page',
            component: lazy(() => import(`components/pages/Magazine/CardNews/CardNewsDetail`)),
            private: true,
          },
          {
            title: 'webtoonList',
            key: '/contents/card/webtoon/list',
            page_name: 'Webtoon/List',
            component: lazy(() => import(`components/pages/Magazine/CardComics`)),
            private: true,
          },
          {
            title: 'webtoonDetail',
            key: '/contents/card/webtoon/detail/:mediaId',
            page_name: 'Webtoon/Detail Page',
            component: lazy(() => import(`components/pages/Magazine/CardComics/CardComicsDetail`)),
            private: true,
          },
          {
            title: 'cardNewsSeries',
            key: '/contents/cardNews/series/:sclasId',
            page_name: 'CardNews/Series Page',
            component: lazy(() => import(`components/pages/Series/Magazine/CardNews`)),
            private: true,
          },
        ],
      },
      /** LG 뉴스 */
      {
        title: 'news',
        key: '/contents/news',
        children: [
          {
            title: 'newsList',
            key: '/contents/news/list',
            page_name: 'LG News/List',
            component: lazy(() => import(`components/pages/News`)),
            private: true,
          },
          {
            title: 'newsDetail',
            key: '/contents/news/detail/:mediaId',
            page_name: 'LG News/Detail Page',
            component: lazy(() => import(`components/pages/News/NewsDetail`)),
            private: true,
          },
          {
            title: 'newsDownload',
            key: '/contents/news/download/detail',
            page_name: 'News/Download Page',
            component: lazy(() => import(`components/pages/News/NewsDownload`)),
            private: true,
          },
        ],
      },
    ],
  },
  /** 커뮤니티 게시판 */
  {
    title: 'community',
    key: '/community',
    children: [
      // 자유 게시판
      {
        title: 'boardList',
        key: '/community/board/list',
        page_name: 'Board/List',
        component: lazy(() => import(`components/pages/Community/Board`)),
        private: true,
      },
      {
        title: 'boardWrite',
        key: '/community/board/write',
        page_name: 'Board/Write',
        component: lazy(() => import(`components/pages/Community/Board/BoardWrite`)),
        private: true,
      },
      {
        title: 'boardEdit',
        key: '/community/board/edit/:boardId',
        page_name: 'Board/Edit',
        component: lazy(() => import(`components/pages/Community/Board/BoardWrite`)),
        private: true,
      },
      {
        title: 'boardDetail',
        key: '/community/board/detail/:boardId',
        page_name: 'Board/Detail',
        component: lazy(() => import(`components/pages/Community/Board/BoardDetail`)),
        private: true,
      },
      // 맛집 게시판
      {
        title: 'topEatsBoardList',
        key: '/community/topEatsBoard/list',
        page_name: 'TopEatsBoard/List',
        component: lazy(() => import(`components/pages/Community/TopEatsBoard`)),
        private: true,
      },
      {
        title: 'topEatsBoardWrite',
        key: '/community/topEatsBoard/write',
        page_name: 'TopEatsBoard/Write',
        component: lazy(() => import(`components/pages/Community/TopEatsBoard/TopEatsBoardWrite`)),
        private: true,
      },
      {
        title: 'topEatsBoardEdit',
        key: '/community/topEatsBoard/edit/:boardId',
        page_name: 'TopEatsBoard/Edit',
        component: lazy(() => import(`components/pages/Community/TopEatsBoard/TopEatsBoardWrite`)),
        private: true,
      },
      {
        title: 'topEatsBoardDetail',
        key: '/community/topEatsBoard/detail/:boardId',
        page_name: 'TopEatsBoard/Detail',
        component: lazy(() => import(`components/pages/Community/TopEatsBoard/TopEatsBoardDetail`)),
        private: true,
      },
      // 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
      // LG인의 선택 (간단 설문)
      // {
      //   title: 'eventSimpleSurveyList',
      //   key: '/community/simpleSurvey/list',
      //   page_name: 'SimpleSurvey/List',
      //   component: lazy(() => import(`components/pages/Community/SimpleSurvey`)),
      //   private: true,
      // },
      // {
      //   title: 'eventSimpleSurveyDetail',
      //   key: '/community/simpleSurvey/detail/:eventId',
      //   page_name: 'SimpleSurvey/Detail',
      //   component: lazy(() => import(`components/pages/Community/SimpleSurvey/SimpleSurveyDetail`)),
      //   private: true,
      // },
      // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
      // 칭찬합니다 게시판
      // {
      //   title: 'mentionsBoardList',
      //   key: '/community/mentionsBoard/list',
      //   page_name: 'MentionsBoard/List',
      //   component: lazy(() => import(`components/pages/Community/MentionsBoard`)),
      //   private: true,
      // },
      // {
      //   title: 'mentionsBoardWrite',
      //   key: '/community/mentionsBoard/write',
      //   page_name: 'MentionsBoard/Write',
      //   component: lazy(() => import(`components/pages/Community/MentionsBoard/MentionsBoardWrite`)),
      //   private: true,
      // },
      // {
      //   title: 'mentionsBoardEdit',
      //   key: '/community/mentionsBoard/edit/:boardId',
      //   page_name: 'MentionsBoard/Edit',
      //   component: lazy(() => import(`components/pages/Community/MentionsBoard/MentionsBoardWrite`)),
      //   private: true,
      // },
      // {
      //   title: 'mentionsBoardDetail',
      //   key: '/community/mentionsBoard/detail/:boardId',
      //   page_name: 'MentionsBoard/Detail',
      //   component: lazy(() => import(`components/pages/Community/MentionsBoard/MentionsBoardDetail`)),
      //   private: true,
      // },
      // 회사 속 직무 게시판
      {
        title: 'departmentBoardList',
        key: '/community/departmentBoard/list',
        page_name: 'DepartmentBoard/List',
        component: lazy(() => import(`components/pages/Community/DepartmentBoard`)),
        private: true,
      },
      {
        title: 'departmentBoardWrite',
        key: '/community/departmentBoard/write',
        page_name: 'DepartmentBoard/Write',
        component: lazy(() => import(`components/pages/Community/DepartmentBoard/DepartmentBoardWrite`)),
        private: true,
      },
      {
        title: 'departmentBoardEdit',
        key: '/community/departmentBoard/edit/:boardId',
        page_name: 'DepartmentBoard/Edit',
        component: lazy(() => import(`components/pages/Community/DepartmentBoard/DepartmentBoardWrite`)),
        private: true,
      },
      {
        title: 'departmentBoardDetail',
        key: '/community/departmentBoard/detail/:boardId',
        page_name: 'DepartmentBoard/Detail',
        component: lazy(() => import(`components/pages/Community/DepartmentBoard/DepartmentBoardDetail`)),
        private: true,
      },
      // 회사 밖 소모임 게시판
      {
        title: 'societyBoardList',
        key: '/community/societyBoard/list',
        page_name: 'SocietyBoard/List',
        component: lazy(() => import(`components/pages/Community/SocietyBoard`)),
        private: true,
      },
      {
        title: 'societyBoardWrite',
        key: '/community/societyBoard/write',
        page_name: 'SocietyBoard/Write',
        component: lazy(() => import(`components/pages/Community/SocietyBoard/SocietyBoardWrite`)),
        private: true,
      },
      {
        title: 'societyBoardEdit',
        key: '/community/societyBoard/edit/:boardId',
        page_name: 'SocietyBoard/Edit',
        component: lazy(() => import(`components/pages/Community/SocietyBoard/SocietyBoardWrite`)),
        private: true,
      },
      {
        title: 'societyBoardDetail',
        key: '/community/societyBoard/detail/:boardId',
        page_name: 'SocietyBoard/Detail',
        component: lazy(() => import(`components/pages/Community/SocietyBoard/SocietyBoardDetail`)),
        private: true,
      },
      // LG LIFE 공지 게시판
      {
        title: 'announcementBoardList',
        key: '/community/announcementBoard/list',
        page_name: 'AnnouncementBoard/List',
        component: lazy(() => import(`components/pages/Community/AnnouncementBoard`)),
        private: true,
      },
      {
        title: 'announcementBoardWrite',
        key: '/community/announcementBoard/write',
        page_name: 'AnnouncementBoard/Write',
        component: lazy(() => import(`components/pages/Community/AnnouncementBoard/AnnouncementBoardWrite`)),
        private: true,
      },
      {
        title: 'announcementBoardEdit',
        key: '/community/announcementBoard/edit/:boardId',
        page_name: 'AnnouncementBoard/Edit',
        component: lazy(() => import(`components/pages/Community/AnnouncementBoard/AnnouncementBoardWrite`)),
        private: true,
      },
      {
        title: 'announcementBoardDetail',
        key: '/community/announcementBoard/detail/:boardId',
        page_name: 'AnnouncementBoard/Detail',
        component: lazy(() => import(`components/pages/Community/AnnouncementBoard/AnnouncementBoardDetail`)),
        private: true,
      },
    ],
  },
  /** 이벤트 */
  {
    title: 'event',
    key: '/event',
    children: [
      {
        title: 'eventList',
        key: '/event/list',
        page_name: 'Event/List',
        component: lazy(() => import(`components/pages/Event`)),
        private: true,
      },
      {
        title: 'eventDetail',
        key: '/event/detail/:eventId',
        page_name: 'Event/Detail Page',
        component: lazy(() => import(`components/pages/Event/EventDetail`)),
        private: true,
      },
      {
        title: 'eventQuizList',
        key: '/event/quiz/list',
        page_name: 'Event/List',
        component: lazy(() => import(`components/pages/Quiz`)),
        private: true,
      },
      {
        title: 'eventSurveyList',
        key: '/event/survey/list',
        page_name: 'Event/List',
        component: lazy(() => import(`components/pages/Survey`)),
        private: true,
      },
      {
        title: 'eventQuizDetail',
        key: '/event/quiz/detail/:eventId',
        page_name: 'Event/Detail Page',
        component: lazy(() => import(`components/pages/Quiz/QuizDetail`)),
        private: true,
      },
      {
        title: 'eventSurveyDetail',
        key: '/event/survey/detail/:eventId',
        page_name: 'Event/Detail Page',
        component: lazy(() => import(`components/pages/Survey/SurveyDetail`)),
        private: true,
      },
      {
        title: 'eventAnniversary75',
        key: '/event/anniversary75',
        page_name: 'Event/Anniversary75',
        component: lazy(() => import(`components/pages/Event/EventAnniversary75`)),
        private: true,
      },
      {
        title: 'eventCustomerInnovation',
        key: '/event/campaign_1',
        page_name: 'Event/EventCustomerInnovation',
        component: lazy(() => import(`components/pages/Event/EventCustomerInnovation`)),
        private: true,
      },
    ],
  },
  /** LG LIFE 소개 */
  {
    title: 'aboutLGLIFE',
    key: '/about/LGLIFE',
    page_name: 'About/LgLife',
    component: lazy(() => import(`components/pages/AboutLGLIFE`)),
    private: true,
  },
  /** LG 명예의 전당 */
  {
    title: 'LgAwardList',
    key: '/LgAward/list',
    page_name: 'LGAwards/List',
    component: lazy(() => import(`components/pages/LgAward`)),
    private: true,
  },
  /** LG 임원 인사 */
  {
    title: 'person',
    key: '/person',
    page_name: 'Person',
    component: lazy(() => import(`components/pages/Person`)),
    private: true,
  },
  /** LG 소개 */
  {
    title: 'introduce',
    key: '/introduce',
    // page_name: 'Intro',
    // component: lazy(() => import(`components/pages/Introduce`)),
    children: [
      {
        title: 'LGWay',
        key: '/introduce/lgway',
        page_name: 'Intro/LGWay',
        component: lazy(() => import(`components/pages/Introduce/LGWay`)),
        private: true,
      },
      {
        title: 'Management',
        key: '/introduce/management',
        page_name: 'Intro/Management',
        component: lazy(() => import(`components/pages/Introduce/Management`)),
        private: true,
      },
      {
        title: 'ESG',
        key: '/introduce/esg',
        children: [
          {
            title: 'ESGList',
            key: '/introduce/esg/list',
            page_name: 'Intro/ESG',
            component: lazy(() => import(`components/pages/Introduce/ESG`)),
            private: true,
          },
          {
            title: 'ESGDetail',
            key: '/introduce/esg/detail/:esgId',
            page_name: 'Intro/ESG/Detail',
            component: lazy(() => import(`components/pages/Introduce/ESG/ESGDetail`)),
            private: true,
          },
        ],
      },
    ],
  },
  /** FAQ - 로그인 */
  {
    title: 'faq',
    key: '/faq',
    page_name: 'FAQ',
    component: lazy(() => import(`components/pages/Faq`)),
    private: true,
  },
  /** 1대1 문의 */
  {
    title: 'qna',
    key: '/qna',
    children: [
      {
        title: 'qnaWrite',
        key: '/qna/write',
        page_name: 'Q&A Write',
        component: lazy(() => import(`components/pages/Qna/QnaWrite`)),
        private: true,
      },
      {
        title: 'qnaWriteCompleted',
        key: '/qna/writecompleted/:mode',
        page_name: 'Q&A Write(completed)',
        component: lazy(() => import(`components/pages/Qna/QnaWriteCompleted`)),
        private: true,
      },
      {
        title: 'qnaEdit',
        key: '/qna/write/:qnaId',
        page_name: 'Q&A Edit',
        component: lazy(() => import(`components/pages/Qna/QnaWrite`)),
        private: true,
      },
      {
        title: 'qnaList',
        key: '/qna/list',
        page_name: 'Q&A List',
        component: lazy(() => import(`components/pages/Qna/QnaList`)),
        private: true,
      },
      {
        title: 'qnaDetail',
        key: '/qna/detail/:qnaId',
        page_name: 'Q&A Detail',
        component: lazy(() => import(`components/pages/Qna/QnaDetail`)),
        private: true,
      },
    ],
  },
  /** 유용한 모음 */
  {
    title: 'tools',
    key: '/tools',
    page_name: 'Tools',
    component: lazy(() => import(`components/pages/Tools`)),
    private: true,
  },
  /** 통합 검색 */
  {
    title: 'search',
    key: '/search',
    page_name: 'Search Result',
    component: lazy(() => import(`components/pages/SearchResult`)),
    private: true,
  },
  /** 마이 페이지 */
  {
    title: 'mypage',
    key: '/mypage',
    page_name: 'My Page',
    component: lazy(() => import(`components/pages/MyPage`)),
    private: true,
  },
  /** 내 정보 수정 */
  {
    title: 'edit',
    key: '/mypage/edit',
    page_name: 'My Page/Edit',
    component: lazy(() => import(`components/pages/MyPage/Edit`)),
    private: true,
  },
  /** 최근 시청한 콘텐츠 */
  {
    title: 'recent',
    key: '/mypage/recent',
    page_name: 'My Page/Recent',
    component: lazy(() => import(`components/pages/MyPage/Recent`)),
    private: true,
  },
  /** 신규 콘텐츠 */
  {
    title: 'currentlyContents',
    key: '/mypage/currentlyContents',
    page_name: 'My Page/currentlyContents',
    component: lazy(() => import(`components/pages/MyPage/CurrentlyContents`)),
    private: true,
  },
  /** 추천 콘텐츠 */
  {
    title: 'recommendContents',
    key: '/mypage/recommendContents',
    page_name: 'My Page/recommendContents',
    component: lazy(() => import(`components/pages/MyPage/RecommendContents`)),
    private: true,
  },
  /** 찜한 콘텐츠 */
  {
    title: 'bookmark',
    key: '/mypage/bookmark',
    page_name: 'My Page/Bookmark',
    component: lazy(() => import(`components/pages/MyPage/Bookmark`)),
    private: true,
  },
  // 사용 하지 않는 좋아요 hook 제거 - 2024.06.27
  // /** 좋아요 표시한 콘텐츠 */
  // {
  //   title: 'like',
  //   key: '/mypage/like',
  //   page_name: 'My Page/Like',
  //   component: lazy(() => import(`components/pages/MyPage/Like`)),
  //   private: true,
  // },
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 내 작성한 게시글 - 칭찬합니다 게시판 */
  // {
  //   title: 'myMentionsBoard',
  //   key: '/mypage/myMentionsBoard',
  //   page_name: 'My Page/My Mentions Board',
  //   component: lazy(() => import(`components/pages/MyPage/Community/MentionsBoard`)),
  //   private: true,
  // },
  /** 내 작성한 게시글 - 회사 옆 맛집 게시판 */
  {
    title: 'myTopEatsBoard',
    key: '/mypage/myTopEatsBoard',
    page_name: 'My Page/My TopEats Board',
    component: lazy(() => import(`components/pages/MyPage/Community/TopEatsBoard`)),
    private: true,
  },
  /** 내 작성한 게시글 - 자유 게시판 */
  {
    title: 'myboard',
    key: '/mypage/myboard',
    page_name: 'My Page/My Board',
    component: lazy(() => import(`components/pages/MyPage/Community/Board`)),
    private: true,
  },
  /** 내 작성한 게시글 - 회사 속 직무 */
  {
    title: 'myDepartmentBoard',
    key: '/mypage/myDepartmentBoard',
    page_name: 'My Page/My Department Board',
    component: lazy(() => import(`components/pages/MyPage/Community/DepartmentBoard`)),
    private: true,
  },
  /** 내 작성한 게시글 - 회사 밖 모임 */
  {
    title: 'mySocietyBoard',
    key: '/mypage/mySocietyBoard',
    page_name: 'My Page/My Society Board',
    component: lazy(() => import(`components/pages/MyPage/Community/SocietyBoard`)),
    private: true,
  },
  /** 개인 정보 취급 방침 */
  {
    title: 'privacy',
    key: '/privacy',
    page_name: 'Privacy',
    component: lazy(() => import(`components/pages/Home/Privacy`)),
    private: true,
  },
  /** 사이트맵 */
  {
    title: 'sitemap',
    key: '/sitemap',
    page_name: 'Sitemap',
    component: lazy(() => import(`components/pages/Home/Sitemap`)),
    private: true,
  },
  /** EP */
  {
    title: 'ep',
    key: '/ep',
    page_name: 'EP Panel',
    component: lazy(() => import(`components/pages/EP`)),
    private: true,
  },
  /** 링크 */
  {
    title: 'link',
    key: '/link',
    page_name: 'Link',
    component: lazy(() => import(`components/pages/Link`)),
    private: true,
  },
  /** 테스트 페이지 */
  {
    title: 'test',
    key: '/contents/video/detail/test/:number',
    page_name: 'test',
    component: lazy(() => import(`components/pages/TestPage`)),
  },
  /** 모바일 체크 페이지 */
  {
    title: 'mobileCheck',
    key: '/mobilecheck',
    page_name: 'Mobile Check',
    component: lazy(() => import(`components/pages/MobileCheck`)),
  },
  /** 자동 로그인 체크 페이지 */
  {
    title: 'autoLoginCheck',
    key: '/autoLoginCheck',
    page_name: 'AutoLogin Check',
    component: lazy(() => import(`components/pages/AutoLoginCheck`)),
  },
  /** 내 맞춤 추천 */
  {
    title: 'recommend',
    key: '/recommend',
    children: [
      {
        title: 'recommendList',
        key: '/recommend/list',
        page_name: 'Recommend/List',
        component: lazy(() => import(`components/pages/Recommend`)),
        private: true,
      },
      {
        title: 'recommendDetail',
        key: '/recommend/detail/:mediaId',
        page_name: 'Recommend/Detail Page',
        component: lazy(() => import(`components/pages/Recommend/RecommendDetail`)),
        private: true,
      },
    ],
  },
];

const flatPath = (acc: Array<Path>, path: Path) => {
  // 자식 노드가 없을 경우
  if (!path.children) {
    acc.push(path);
    return acc;
  }

  // 자식 노드가 있을 경우
  acc.push(path);

  path.children.map(p => {
    // 재귀 호출
    flatPath(acc, p);
  });
  return acc;
};

const flatPaths = paths.map(path => flatPath([], path)).flat();

const getPage = (pathName: string) => {
  return flatPaths.filter(p => {
    return matchPath(pathName, {
      path: p.key,
      exact: true,
    });
  });
};

export default {
  paths,
  flatPaths,
  /** path title 값 통해 key 값 가져오기 */
  getPath(
    title: string,
    optional?: {
      resultFiledNm: 'key' | 'page_name' | 'title';
    }
  ) {
    const lowerCaseTitle = title.toLowerCase();
    if (!optional) return flatPaths.find(p => p.title.toLowerCase() === lowerCaseTitle)?.key || '';
    const { resultFiledNm } = optional;
    if (resultFiledNm === 'page_name')
      return flatPaths.find(p => p.title.toLowerCase() === title.toLowerCase())?.page_name || '';
    if (resultFiledNm === 'title')
      return flatPaths.find(p => p.title.toLowerCase() === title.toLowerCase())?.title || '';
    return flatPaths.find(p => p.title.toLowerCase() === title.toLowerCase())?.key || '';
  },
  getPageName(pathName: string): string | undefined {
    const page = getPage(pathName);

    if (page.length === 0) {
      return '404';
    }

    return page[0].page_name;
  },
  isPrivate(pathName: string): boolean | undefined {
    const page = getPage(pathName);

    if (page.length > 0) {
      return page[0].private;
    }
    return undefined;
  },
};
