import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AutoSizer, Grid, InfiniteLoader, WindowScroller } from 'react-virtualized';
import Pagination from 'react-js-pagination';
import classNames from 'classnames';
import { parse } from 'query-string';
import { useEvent } from 'hooks';
import { imageUrl } from 'utils/utils';
import SectionTitle from 'components/atoms/SectionTitle';
import ControlDropdown from 'components/atoms/ControlDropdown';
import EventCard, { EventListItem } from 'components/molecules/EventCard';
import { all_click_datalayer_push, getEventGAProps } from 'utils/GAUtils';
import { clearListCache, getHtmlFormatByEscapeHtmlData } from 'utils/LGLifeUtils';
import throttle from 'lodash/throttle';
import { GET_LOADED_ENVIRONMENT_TYPE, VERTUAL_SCROLL_THRESHOLD, WINDOW_RESIZE_THROTTLE } from 'commons/constants';

import ControlPopup from '../../organisms/Popup/ControlPopup';
import { MESSAGE_EVENT_IDS } from './constants';

const EventList = () => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;
  const { eventData, searchOptions, setSearchOptions } = useEvent();

  const [eventList, setEventList] = useState([] as EventResDto[]);
  const [currentDt, setCurrentDt] = useState('');
  const [eventStatus, setEventStatus] = useState('ALL');

  const [viewType, setViewType] = useState('card');
  const [sort, setSort] = useState('postDt,desc');
  const [controlPopup, setControlPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [keywordActive, setKeywordActive] = useState(Boolean(keyword));
  const [isPopState, setIsPopState] = useState(false);

  const controlRef = useRef<HTMLDivElement>(null);
  const infiniteLoaderRef = useRef<any>();

  const [columnCount, setColumnCount] = useState(3);
  const [rowHeight, setRowHeight] = useState<number>(0);

  const totalPage = useRef<number>(0);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('eventList', JSON.stringify(eventList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  useEffect(() => {
    if (history.action === 'POP' && eventList.length === 0 && localStorage.getItem('eventList')) {
      setEventList(JSON.parse(localStorage.getItem('eventList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  const handleScroll = () => {
    // lodash throttle?
    if ((window.scrollY >= 88 && window.innerWidth > 1023) || (window.scrollY >= 61 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (listData?.list === 'event' && history.action === 'POP') {
      // console.log(listData);
      setSort(listData.sort);
      setEventStatus(listData.filter);
      setViewType(listData.viewType);
      if (listData.viewType === 'list') setCurrentPage(listData.currentPage);
      else setCurrentPage(1);
    } else {
      window.sessionStorage.clear();
    }

    updateThrottleHandler();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateThrottleHandler);
    window.onbeforeunload = () => {
      clearListCache();
    };
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateThrottleHandler);
    };
  }, []);

  const updateSize = () => {
    const windowSize = { width: window.innerWidth, height: window.innerHeight };

    if (windowSize) {
      // console.log("windowSize", windowSize);

      if (windowSize.width < 1024) {
        setColumnCount(1);
      } else {
        setColumnCount(3);
      }
    }
  };

  const updateThrottleHandler = useCallback(throttle(updateSize, WINDOW_RESIZE_THROTTLE), []);

  useEffect(() => {
    if (eventData) {
      totalPage.current = eventData.totalPage;

      if (isPopState && eventData.page === currentPage) {
        setIsPopState(false);
        return;
      }

      setCurrentDt(eventData.currentDt);
      if (viewType === 'card') {
        if (currentPage === 1 && eventData.page === 1) {
          setEventList(eventData.eventList);
        } else if (eventData.totalAmount > eventList?.length) {
          setEventList(eventList.concat(eventData.eventList));
        }
        // else if (eventData.page === 1) {
        //   setCurrentPage(eventData.page);
        //   setEventList(eventData.eventList);
        // } else if (currentPage !== eventData.page) {
        //   setCurrentPage(eventData.page);
        //   setEventList(eventList.concat(eventData.eventList));
        // }
      } else {
        setCurrentPage(eventData.page);
        setEventList(eventData.eventList);
      }
    }
  }, [eventData]);

  useEffect(() => {
    setEventList([]);
    setCurrentPage(1);
  }, [keyword]);

  useEffect(() => {
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
    }
  }, [eventList, infiniteLoaderRef.current]);

  useEffect(() => {
    setSearchOptions({
      ...searchOptions,
      sort,
      eventStatus,
      page: currentPage,
      searchTag: keywordActive ? keyword : '',
    });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({ list: 'event', sort: sort, filter: eventStatus, currentPage: currentPage, viewType: viewType })
    );
  }, [sort, eventStatus, currentPage, viewType, keywordActive, keyword]);

  useEffect(() => {
    setCurrentPage(1);
  }, [keywordActive]);

  const _isRowLoaded = ({ index }) => {
    const rowCount = Math.floor(eventList.length / columnCount);
    return index < rowCount - 1;
  };
  const _loadMore = () => {
    if (totalPage.current && currentPage < totalPage.current) {
      setCurrentPage(currentPage + 1);
    }
  };

  const messageEventIds = MESSAGE_EVENT_IDS.find(data => data.environmentType === GET_LOADED_ENVIRONMENT_TYPE());

  const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    // console.log("_cellRenderer", key)
    const event = eventList[rowIndex * columnCount + columnIndex];

    /** 이벤트 dim 적용 여부 */
    const isDimEvent = event?.eventStatus === 'END' && event?.eventType === 'EDITOR';

    if (!event) return;

    return (
      <div key={key} style={style}>
        {/* 75주년 보드 이벤트 임시 덮어쓰기 */}
        {messageEventIds && messageEventIds.eventIds.find(e => e.eventId === event.eventId) ? (
          <Link
            to={messageEventIds?.eventIds.find(e => e.eventId === event.eventId)?.linkUrl || '/event/list'}
            className="card"
          >
            <div className={classNames(['card_thumb', { event_end: isDimEvent }])}>
              <img
                src={imageUrl(event.thumbImageUri) || '/assets/images/illust/illust-img-event.png'}
                className="card_thumb_img"
              />
              {event.eventStatus === 'ING' && <span className="card_thumb_flag is-event">진행 중</span>}
              {event.eventStatus === 'END' && (
                <>
                  <div className="card_dim"></div>
                  <span className="card_thumb_flag is-done">종료</span>
                </>
              )}
            </div>
            <div className="card_con">
              <div className="card_title has-dday">
                {getHtmlFormatByEscapeHtmlData(event.title || '')}
                {/* {event.eventStatus === 'ING' && (
                  <span className="card_title_dday">{`D-${ddayCalc(event.eventEndDt)}`}</span>
                )} */}
                {/* {event.eventStatus === 'END' && <span className="card_title_dday is-done">D-0</span>} */}
              </div>
              <div className="card_sub">
                {/* <div className="card_time">{relativeTime(event.postDt, currentDt)}</div> */}
              </div>
            </div>
          </Link>
        ) : (
          <Link
            to={`detail/${event.eventId}`}
            className={classNames({ card: true })}
            {...getEventGAProps({
              type: event.eventType,
              url: `${window.location.hostname}${event.linkUri ? event.linkUri : ''}`,
              title: getHtmlFormatByEscapeHtmlData(event.title || ''),
              dataContentBelong: '이벤트',
              status: event.eventStatus,
              dataImgUrl: event.thumbImageUri,
            })}
            onClick={e => {
              all_click_datalayer_push(e.currentTarget);
            }}
          >
            <EventCard {...event} currentDt={currentDt} />
          </Link>
        )}
      </div>
    );
  };

  const _calculateRowHeight = (width: number) => {
    // console.log("_calculateRowHeight");

    if (window.innerWidth < 1024) {
      setRowHeight(width * 0.6047 + 126);
    } else {
      setRowHeight((width / columnCount) * 0.5644 + 140);
    }
  };

  const onAutoSizerResize = param => {
    const { height, width } = param;
    // console.log("onAutoSizerResize (height, width)", height, width);
    _calculateRowHeight(width);
  };

  const _eventList = useMemo(() => {
    return (
      <>
        {eventList && (
          <InfiniteLoader
            isRowLoaded={_isRowLoaded}
            loadMoreRows={_loadMore}
            rowCount={Math.ceil(eventList.length / columnCount)}
            threshold={VERTUAL_SCROLL_THRESHOLD}
            ref={ref => (infiniteLoaderRef.current = ref)}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, scrollTop }) => (
                  <AutoSizer disableHeight onResize={onAutoSizerResize}>
                    {({ width }) => {
                      if (width == 0) return;
                      return (
                        <Grid
                          cellRenderer={_cellRenderer}
                          columnWidth={width / columnCount}
                          columnCount={columnCount}
                          rowCount={Math.ceil(eventList.length / columnCount)}
                          rowHeight={rowHeight}
                          width={width}
                          height={height}
                          ref={registerChild}
                          overscanRowCount={VERTUAL_SCROLL_THRESHOLD}
                          onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                            onRowsRendered({
                              startIndex: rowStartIndex,
                              stopIndex: rowStopIndex,
                            });
                          }}
                          scrollTop={scrollTop}
                          autoHeight
                        />
                      );
                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </>
    );
  }, [eventList, columnCount, rowHeight]);

  return (
    <>
      <div className="content_inner">
        <div className="section event_list">
          <SectionTitle title="상시 이벤트" />
          <div className="section_cont section_sticky" ref={controlRef}>
            <div className="section_cont_top">
              <div className="section_info">
                <div className="control">
                  <div className="control_tag">
                    <div className="control_tag_wrap">
                      <div className="swiper-container">
                        <ul className="control_tag_list">
                          <li
                            className={classNames('tag_item', 'tag-all', {
                              'is-active': eventStatus === 'ALL' && !keywordActive,
                            })}
                            key="ALL"
                          >
                            <a
                              href="#"
                              className="tag_link"
                              onClick={e => {
                                e.preventDefault();
                                setKeywordActive(false);
                                setEventStatus('ALL');
                                setCurrentPage(1);
                                clearListCache();
                              }}
                            >
                              전체
                            </a>
                          </li>
                          <li
                            className={classNames('tag_item', { 'is-active': !keywordActive && eventStatus === 'ING' })}
                            key="ING"
                          >
                            <a
                              href="#"
                              className="tag_link"
                              onClick={e => {
                                e.preventDefault();
                                setKeywordActive(false);
                                setEventStatus('ING');
                                setCurrentPage(1);
                                clearListCache();
                              }}
                            >
                              진행 중
                            </a>
                          </li>
                          <li
                            className={classNames('tag_item', { 'is-active': !keywordActive && eventStatus === 'END' })}
                            key="END"
                          >
                            <a
                              href="#"
                              className="tag_link"
                              onClick={e => {
                                e.preventDefault();
                                setKeywordActive(false);
                                setEventStatus('END');
                                setCurrentPage(1);
                                clearListCache();
                              }}
                            >
                              종료
                            </a>
                          </li>
                          {keyword ? (
                            <li
                              className={classNames('tag_item', 'search_tag', 'swiper-slide', {
                                'is-active': keywordActive,
                              })}
                              key={keyword}
                            >
                              <a
                                className="tag_link"
                                href={`#${keyword}`}
                                onClick={e => {
                                  e.preventDefault();
                                  setKeywordActive(!keywordActive);
                                  setEventStatus('ALL');
                                  setCurrentPage(1);
                                }}
                              >
                                {keyword}
                              </a>
                              <button
                                type="button"
                                className="remove_write_tag"
                                onClick={() => {
                                  history.push({
                                    pathname: location.pathname,
                                  });
                                }}
                              >
                                <span className="ab_text">태그 삭제</span>
                              </button>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="control_right is-pc">
                    <ul className="control_type">
                      <li className={classNames(['type_item', { 'is-active': viewType === 'card' }])}>
                        <a
                          className="type_link card-view"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setEventList([]);
                            setViewType('card');
                            setCurrentPage(1);
                            clearListCache();
                          }}
                        >
                          <span className="is-blind">카드형</span>
                        </a>
                      </li>
                      <li className={classNames(['type_item', { 'is-active': viewType === 'list' }])}>
                        <a
                          className="type_link list-view"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setViewType('list');
                            setCurrentPage(1);
                            clearListCache();
                          }}
                        >
                          <span className="is-blind">리스트형</span>
                        </a>
                      </li>
                    </ul>
                    <ControlDropdown
                      initial={sort}
                      clickSort={s => {
                        setSort(s);
                        setCurrentPage(1);
                        clearListCache();
                      }}
                      sortList={[
                        { title: '최신순', data: 'postDt,desc' },
                        { title: '조회수순', data: 'readCnt,desc' },
                      ]}
                    />
                  </div>
                  <button
                    type="button"
                    className="filter-button is-mobile"
                    onClick={() => {
                      setControlPopup(true);
                    }}
                  >
                    <span className="ab_text">검색 필터</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="main_row card_list">
              {viewType === 'card' && (
                <>
                  <div className="card_row is-small has-hover">
                    <div className="card_wrapper">{_eventList}</div>
                  </div>
                </>
              )}
              {eventData && viewType === 'list' && (
                <>
                  <div className="list_table row-wide is-show">
                    <table>
                      <colgroup>
                        <col style={{ width: '90px' }} />
                        <col style={{ width: '73px' }} />
                        <col style={{ width: '60%' }} />
                        <col style={{ width: '6.29%' }} />
                        <col style={{ width: '6.29%' }} />
                        <col style={{ width: '6.29%' }} />
                        <col style={{ width: '93px' }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="list_head">구분</th>
                          <th className="list_head">기한</th>
                          <th className="list_head">이벤트명</th>
                          <th className="list_head">조회수</th>
                          <th className="list_head">댓글</th>
                          <th className="list_head">좋아요</th>
                          <th className="list_head">등록일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventList.map((event, idx) => {
                          // 75주년 보드 이벤트 임시 덮어쓰기
                          if (event.eventId === 'EVT000000000000000000000080') {
                            return (
                              <tr className="list_item" key={idx}>
                                {event.eventStatus === 'ING' && (
                                  <>
                                    <td className="list_cell cell_divider cell_tag">
                                      <span className="card_thumb_flag is-event">진행 중</span>
                                    </td>
                                    <td className="list_cell cell_dday">
                                      {/* <span className="list_dday">{`D-${ddayCalc(event.eventEndDt)}`}</span> */}
                                    </td>
                                  </>
                                )}
                                {event.eventStatus === 'END' && (
                                  <>
                                    <td className="list_cell cell_divider cell_tag">
                                      <span className="card_thumb_flag is-done">종료</span>
                                    </td>
                                    <td className="list_cell cell_dday">
                                      {/* <span className="list_dday is-done">D-0</span> */}
                                    </td>
                                  </>
                                )}
                                <td className="list_cell cell_title">
                                  <Link to="/event/anniversary75" className="list_title_link">
                                    <span className="list_title">
                                      {getHtmlFormatByEscapeHtmlData(event.title || '')}
                                    </span>
                                  </Link>
                                </td>
                                <td className="list_cell cell_small"></td>
                                <td className="list_cell cell_small"></td>
                                <td className="list_cell cell_small"></td>
                                <td className="list_cell">
                                  {/* <div className="list_date">{relativeTime(event.postDt, currentDt)}</div> */}
                                </td>
                              </tr>
                            );
                          }
                          return (
                            <tr className="list_item" key={idx}>
                              <EventListItem {...event} currentDt={currentDt} />
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="list_pagination">
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={9}
                        totalItemsCount={eventData.totalAmount}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="first"
                        itemClassPrev="prev"
                        itemClassNext="next"
                        itemClassLast="last"
                        onChange={page => {
                          setSearchOptions({ ...searchOptions, page });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ControlPopup
        initial={sort}
        sortList={[
          { title: '최신순', data: 'postDt,desc' },
          { title: '조회수순', data: 'readCnt,desc' },
        ]}
        isActive={controlPopup}
        clickSort={s => {
          setSort(s);
          setCurrentPage(1);
          clearListCache();
        }}
        onClose={() => setControlPopup(false)}
      />
    </>
  );
};

export default EventList;
