import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { stringify } from 'querystring';
import paths from 'commons/paths';
import { useAuth, useNotice } from 'hooks';
import useSearch from 'hooks/useSearch';
import { checkCicUpdate, openLeftSideMenu } from 'utils/LGLifeUtils';
import MyInfoContext from 'contexts/MyInfoContext';
import AccountPopup from './AccountPopup';
import CicUpdatePopup from './CicUpdatePopup';
import ProfileImage from 'components/atoms/ProfileImage';
import classNames from 'classnames';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import { callGASearchEvent } from '../../../utils/GAUtils';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useMyInfo from '../../../hooks/useMyInfo';
import NoticeArea from './Notice/NoticeArea';

type SearchHistory = { keyword: string; time: Date };

const Header = (): React.ReactElement => {
  const navElement = document.querySelector('.nav');

  const history = useHistory();
  const location = useLocation();

  const { newNoticeTotalCount, userProfileData, newNoticeCountList } = useNotice();
  const { searchString, searchKeyword, setSearchString, autoCompleteList } = useSearch();
  const { info, checkAnniversary, workDays } = useMyInfo();

  const [noticePopup, setNoticePopup] = useState(false);
  const [accountPopup, setAccountPopup] = useState(false);
  const [cicUpdatePopup, setCicUpdatePopup] = useState(false);
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [delTrigger, setDel] = useState('');
  const [isOpenedLeftSideMenu, setIsOpenedLeftSideMenu] = useState<boolean>(
    !!localStorage.getItem('isOpenedLNB')
      ? localStorage.getItem('isOpenedLNB') === 'true'
      : !navElement?.classList?.contains('is-simple')
  );

  const [searchActive, setSearchActive] = useState(false);
  const myInfoContext = useContext(MyInfoContext);

  const [screenSize, setScreenSize] = useState('lg');

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchHelperRef = useRef<HTMLDivElement>(null);
  const helperListRef = useRef<HTMLUListElement>(null);
  const noticeBtnRef = useRef<HTMLButtonElement>(null);
  const accoutnBtnRef = useRef<HTMLAnchorElement>(null);
  const headerInfoRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else setScreenSize('sm');
  };

  // PC 화면의 경우, 클릭 시 검색 자동 완성 창 영역을 벗어날 경우 검색 자동 완성 창 닫힘 처리
  // 모바일 화면의 경우, 자동 완성 창이 화면을 모두 채우는 형태이므로 별도 처리 X
  useOutsideClick(searchHelperRef, () => {
    if (searchActive && document.activeElement !== searchInputRef.current && screenSize === 'lg') {
      setSearchActive(false);
    }
  });

  const handleHighlight = useCallback(
    input => {
      const regExp = new RegExp(`'\${searchString}'`, 'gi');
      const result = regExp.exec(input);

      return result?.length ? input.replace(regExp, `<span class="helper_highlight">${result[0]}</span>`) : input;
    },
    [searchString]
  );

  const getLocalSearchHistory = useCallback(() => {
    return JSON.parse(localStorage.getItem('search-history') || JSON.stringify([]));
  }, []);

  const handleSearchHistory = useCallback((value: string) => {
    let newSearchHistory = getLocalSearchHistory().map(item => ({ ...item })) as SearchHistory[];

    const targetIndex = newSearchHistory.map(item => item.keyword).indexOf(value);

    if (targetIndex > -1) {
      newSearchHistory.splice(targetIndex, 1);
    }

    newSearchHistory.unshift({ keyword: value, time: new Date() });

    if (newSearchHistory.length > 5) {
      newSearchHistory = newSearchHistory.slice(0, 5);
    }

    localStorage.setItem('search-history', JSON.stringify(newSearchHistory));
  }, []);

  const deleteSearchHistory = useCallback((value: string) => {
    let newSearchHistory = getLocalSearchHistory().map(item => ({ ...item })) as SearchHistory[];
    const targetIndex = newSearchHistory.map(item => item.keyword).indexOf(value);

    newSearchHistory.splice(targetIndex, 1);

    localStorage.setItem('search-history', JSON.stringify(newSearchHistory));
  }, []);

  const goToSearch = useCallback((keyword, detailPage?: string) => {
    if (!keyword) return false;
    if (window.searchForGA === 'function') {
      window.searchForGA('통합검색', keyword.trim());
    }

    const value = keyword.trim();

    if (value) {
      handleSearchHistory(value);

      callGASearchEvent({
        keyword: value as string,
        searchType: 'mainSearch',
      });

      if (detailPage) {
        history.push(detailPage as string);
      } else {
        history.push({
          pathname: paths.getPath('search'),
          search: stringify({ keyword: value }),
        });
      }

      setSearchString(value);
    }

    setSearchActive(false);
    document.querySelector('body')?.classList.remove('no-scroll-mobile');
  }, []);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
  };

  useEffect(() => {
    if (checkCicUpdate()) {
      setCicUpdatePopup(true);
    }
  });

  useEffect(() => {
    if (noticePopup) document.querySelector('body')?.classList.add('no-scroll-mobile');
    else document.querySelector('body')?.classList.remove('no-scroll-mobile');
  }, [noticePopup]);

  useEffect(() => {
    setSearchActive(false);
    searchInputRef.current?.blur();
    setSearchString(searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    if (isOpenedLeftSideMenu) {
      // LNB 펼침
      openLeftSideMenu(true);
      return;
    }

    // LNB 축소
    openLeftSideMenu(false);
  }, [isOpenedLeftSideMenu]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setCicUpdate = () => {
    setCicUpdatePopup(false);
    useAuth()
      .putCicUserInfo()
      .then(res => {
        if (res.resultCode === 200) {
          setAlertPopContents('업데이트 되었습니다.');
        } else {
          setAlertPopContents(res.resultMessage);
        }
        setIsAlertPopupOpen(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const keyHandler = e => {
    const parentEl = e.target.parentElement;
    if (parentEl) {
      if (e.key === 'ArrowDown') {
        const nextEl = parentEl.nextElementSibling
          ? parentEl.nextElementSibling.firstElementChild
          : parentEl.parentElement.firstElementChild.firstElementChild;
        nextEl?.focus();
      }
      if (e.key === 'ArrowUp') {
        const prevEl = parentEl.previousElementSibling
          ? parentEl.previousElementSibling.firstElementChild
          : parentEl.parentElement.lastElementChild.firstElementChild;
        prevEl?.focus();
      }
    }
  };

  const onClickHome = e => {
    e.preventDefault();
    setSearchString('');

    if (location.pathname === '/') {
      window.location.reload();
      return;
    }

    history.push(paths.getPath('home'));
  };

  /**
   * LG Days 클릭
   */
  const onClickLGDays = useCallback(() => {
    const nowPath = paths.getPath('mypage');
    if (location.pathname === nowPath) {
      return window.location.reload();
    }
    return history.push(nowPath);
  }, [location.pathname, info]);

  /**
   * LNB 확대/축소 버튼 클릭 이벤트
   */
  const toggleLNBControlBtn = (isOpen: boolean) => {
    if (isOpen) {
      setIsOpenedLeftSideMenu(true);
      return;
    }
    setIsOpenedLeftSideMenu(false);
  };

  return (
    <>
      <header className={classNames('header', { 'mobile-full': searchActive })}>
        <div className="header_info" ref={headerInfoRef}>
          <div className="header_logo">
            <h1 className="logo_link">
              <a href="#" onClick={onClickHome}>
                <span className="ab_text">LG LIFE</span>
              </a>
            </h1>
          </div>
        </div>

        <div className={classNames('header_search', { 'is-active': searchActive })}>
          <div className="header_search_box">
            <input
              type="text"
              className="header_search_ip"
              placeholder="LG LIFE에서 궁금한 키워드를 검색해 보세요!"
              value={searchString}
              onChange={onChangeSearch}
              onKeyDown={e => {
                if (e.key === 'ArrowDown') {
                  (document.querySelectorAll('.helper_rel_text')[0] as HTMLAnchorElement)?.focus();
                }
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  goToSearch(searchString);
                }
              }}
              onFocus={() => {
                setSearchActive(true);
              }}
              ref={searchInputRef}
              id="headerSearchIp"
            />
            <label className="ab_text" htmlFor="headerSearchIp">
              통합검색
            </label>
            <button
              type="button"
              className="header_back ico_back is-mobile"
              onClick={() => {
                setSearchActive(false);
                setSearchString(searchKeyword);
                document.body.classList.remove('no-scroll-mobile');
              }}
            >
              <span className="ab_text">뒤로가기</span>
            </button>
            <button
              type="button"
              className="header_delete ico_close_white is-mobile"
              onClick={() => {
                setSearchString('');
                searchInputRef.current?.focus();
              }}
            >
              <span className="ab_text">검색어 삭제</span>
            </button>

            <div
              className={classNames('search_helper custom_scroll', {
                'is-active': searchActive,
              })}
              ref={searchHelperRef}
            >
              {/* <!-- related list --> */}
              <ul className="search_helper_list helper_rel_list" ref={helperListRef}>
                {autoCompleteList?.autoCompleteList?.map((item: SearchAutoCompleteResDto, index: number) => (
                  <li className="helper_item" key={index}>
                    <a
                      href={item.linkUri as string}
                      className="helper_rel_text"
                      dangerouslySetInnerHTML={{ __html: handleHighlight(item.title) }}
                      onKeyDown={keyHandler}
                      onClick={e => {
                        e.preventDefault();
                        goToSearch(item.title, item.linkUri as string);
                      }}
                    >
                      {/* <div style={{ display: 'none' }}>{item.title}</div> */}
                    </a>
                  </li>
                ))}
                {!searchString.trim() &&
                  getLocalSearchHistory()?.map((item: SearchHistory, index: number) => (
                    <li className="helper_item" key={index}>
                      <a
                        href={`${paths.getPath('search')}?keyword=${item.keyword}`}
                        className="helper_rel_text"
                        onKeyDown={keyHandler}
                        onClick={e => {
                          e.preventDefault();
                          goToSearch(item.keyword);
                        }}
                      >
                        {item.keyword}
                      </a>
                      <button
                        className="helper_item_delete"
                        onClick={e => {
                          deleteSearchHistory(item.keyword);
                          setDel(Date());
                        }}
                      >
                        <span className="ab_text">삭제</span>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <button
              type="button"
              className="search_button ico_search_white"
              onClick={() => {
                goToSearch(searchString);
              }}
            >
              <span className="ab_text">검색</span>
            </button>
          </div>
        </div>

        <div className="header_util">
          {info && (
            <a className="user-day" href={''} onClick={onClickLGDays}>
              <span className="name">
                <span>{info?.name || ''}</span>님의&nbsp;
              </span>
              <strong className="bold">LG DAYS</strong>
              <strong className="day">{!!checkAnniversary ? checkAnniversary : String(`+ ${workDays}`)}</strong>
            </a>
          )}
          <div className="header_util_button is-mobile">
            <button
              type="button"
              className="header_util_icon util_search ico_search_gray"
              onClick={() => {
                setSearchActive(true);
                document.body.classList.add('no-scroll-mobile');
                searchInputRef.current?.focus();
              }}
            >
              <span className="ab_text">통합검색 버튼</span>
            </button>
          </div>
          <div className="header_util_button">
            <button
              type="button"
              id="main_alarm_click"
              className="header_util_icon util_noti ico_noti is-new"
              ref={noticeBtnRef}
              onClick={() => {
                setNoticePopup(!noticePopup);
                // noticeList.map((n) => {
                //   if(n.readYn === 'N') putNoticeRead(n.notiId as string);
                // })
              }}
            >
              <span className="ab_text">알림 버튼</span>
              {newNoticeTotalCount > 0 ? (
                <span className="noti_count">{newNoticeTotalCount >= 10 ? '9+' : newNoticeTotalCount}</span>
              ) : (
                ''
              )}
              <span className="ab_text">개의 새로운 알림</span>
            </button>
          </div>
          <div className="header_util_button">
            <a
              className="header_util_icon util_profil"
              href="#"
              ref={accoutnBtnRef}
              onClick={e => {
                e.preventDefault();
                setAccountPopup(!accountPopup);
              }}
            >
              <ProfileImage
                name={userProfileData?.name || ''}
                score={myInfoContext.actScore || 0}
                image={myInfoContext.profileImage}
                isAdmin={myInfoContext.isAdmin}
                isHeader={true}
              />
              <span className="ab_text">마이 페이지</span>
            </a>
          </div>
        </div>
      </header>
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
      {noticePopup && (
        <NoticeArea
          onClose={() => setNoticePopup(false)}
          buttonRef={noticeBtnRef}
          newNoticeCountList={newNoticeCountList}
        />
      )}
      {accountPopup && <AccountPopup onClose={() => setAccountPopup(false)} buttonRef={accoutnBtnRef} />}
      {cicUpdatePopup && <CicUpdatePopup onCancel={() => setCicUpdatePopup(false)} onOk={() => setCicUpdate()} />}
    </>
  );
};

export default Header;
