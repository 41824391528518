import React, { useEffect, useState } from 'react';
import SectionTitle from 'components/atoms/SectionTitle';
import ControlDropdown, { SortItem } from 'components/atoms/ControlDropdown';
import { useFaq } from 'hooks';
import FaqAccordion from './components/FaqAccordion';
// import { Category } from 'types';

export interface FaqCate {
  cateNm: string;
  faqList: Faq[];
}
export interface Faq {
  faqId: string;
  orderNum: number;
  question: string;
  reply: string;
  cateId: string;
  cateNm: string;
}

const Faq = () => {
  const { faqData } = useFaq();
  const [cateId, setCateId] = useState('');
  const [categoryList, setCategoryList] = useState([] as SortItem[]);
  const [faqList, setFaqList] = useState([] as FaqCategoryListResDto[]);

  // useEffect(() => {
  //   if (faqCategory) {
  //     setCategoryList(
  //       [{ title: '전체', data: '' }].concat(
  //         faqCategory.categoryList.map((c) => ({ title: c.cateNm, data: c.cateId })),
  //       ),
  //     );
  //   }
  // }, [faqCategory]);

  useEffect(() => {
    if (faqData) setFaqList(faqData.faqCategoryList);
  }, [faqData]);

  // useEffect(() => {
  //   setSearchOptions({ ...searchOptions, cateId });
  // }, [cateId]);

  return (
    <>
      <div className="content_inner">
        <div className="section faq">
          <SectionTitle title="FAQ" />
          <div className="section_cont">
            <div className="cont_header">
              <div className="cont_header_inner" style={{ backgroundImage: `url('/assets/images/img/img-faq.png')` }}>
                <strong className="cont_title">FAQ</strong>
                <p className="cont_desc">“FAQ를 통해서 원하는 답변을 쉽고 빠르게 찾아보세요”</p>
                <p className="cont_desc_sub">
                  LG LIFE 사이트 관련 질문 중 가장 빈도수가 높은 질문들을 모아, 답변을 해드리고 있습니다.
                </p>
              </div>
            </div>

            {
              faqList?.length > 0 && (
                <FaqAccordion faqCateList={faqList} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
