import React, { useEffect, useRef, useState } from 'react';
import { dateYYYYMMDD } from 'utils/utils';
import { Link, useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import classNames from 'classnames';
import { all_click_datalayer_push, getContentsButtonGAProps, getContentTagGAProps } from 'utils/GAUtils';
import paths from 'commons/paths';
import { useVideo } from 'hooks';
import { ContentsDetailType } from '../../../types/enum/ContentsDetailType';

interface DetailInfoProps {
  detailInfoData?: MediaDetailResDto;
  like: () => void;
  openSharePopup: () => void;
  mediaType?: 'VIDEO' | 'MAGAZINE';
  openScript?: () => void;
}

const DetailInfo = (props: DetailInfoProps) => {
  const { detailInfoData } = props;
  const history = useHistory();
  const { setVideoVisible } = useVideo();
  const [motion, setMotion] = useState(false);
  const [screenSize, setScreenSize] = useState('lg');
  const likeRef = useRef<any>();

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="detail_info is-small"
        style={{ paddingTop: props.mediaType === 'VIDEO' ? (screenSize === 'sm' ? '56.25%' : '16px') : '' }}
      >
        <div className="detail_info_con">
          <ul className="detail_info_tag">
            {detailInfoData?.mediaTag &&
              detailInfoData?.mediaTag
                .split('#')
                .slice(1)
                .map((t, index) => (
                  <Link
                    to={{
                      pathname: paths.getPath('search'),
                      search: stringify({ keyword: t }),
                    }}
                    key={index}
                    {...getContentTagGAProps('동영상태그', t)}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <li>#{t}</li>
                  </Link>
                ))}
          </ul>
          <div className="detail_info_title">{detailInfoData?.title}</div>
          <div className="detail_info_sub">
            {detailInfoData?.cateType !== 'GROUP' && (
              <span className="detail_info_views">
                조회수<span className="views">{detailInfoData?.readCnt}</span>회
              </span>
            )}
            <span className="detail_info_date">{dateYYYYMMDD(detailInfoData?.postDt)}</span>
          </div>
        </div>

        <ul className={classNames('detail_info_more', { 'is-pc': props.mediaType !== 'VIDEO' })}>
          {props.detailInfoData?.cateType !== 'GROUP' && (
            <li>
              <a
                className={classNames('detail_info_link', 'is-likes', {
                  'is-active': detailInfoData?.likeStatus === 'Y',
                  'is-motion': motion,
                })}
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setMotion(true);
                  if (detailInfoData?.likeStatus === 'N') props.like();
                  all_click_datalayer_push(e.currentTarget);
                }}
                {...getContentsButtonGAProps({
                  buttonType: '좋아요',
                  contentType: detailInfoData?.cateType as string,
                  magazineType:
                    detailInfoData?.contentsDetailType === ContentsDetailType.CARD_COMICS.toString()
                      ? ContentsDetailType.CARD_COMICS.toString()
                      : 'CARD',
                  tags: detailInfoData?.mediaTag as string,
                  title: detailInfoData?.title as string,
                  time: detailInfoData?.videoTotalTime ? detailInfoData.videoTotalTime : -1, // video total time ? video total time : -1
                  dataContentCategory: detailInfoData?.cateNm as string,
                  creatorNm: detailInfoData?.creatorNm as string,
                  brightcoveId: detailInfoData?.videoId as string,
                  mediaContentsType: detailInfoData?.mediaContentType as string,
                })}
              ></a>
              <div className="detail_info_text">{detailInfoData?.likeCnt}</div>
            </li>
          )}
          {props.mediaType === 'VIDEO' && (
            <>
              <li>
                <a
                  className="detail_info_link script"
                  href="#"
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                    if (props.openScript) {
                      props.openScript();
                    }
                  }}
                  {...getContentsButtonGAProps({
                    buttonType: '원고 보기',
                    contentType: detailInfoData?.cateType as string,
                    magazineType:
                      detailInfoData?.contentsDetailType === ContentsDetailType.CARD_COMICS.toString()
                        ? ContentsDetailType.CARD_COMICS.toString()
                        : 'CARD',
                    tags: detailInfoData?.mediaTag as string,
                    title: detailInfoData?.title as string,
                    time: detailInfoData?.videoTotalTime ? detailInfoData.videoTotalTime : -1,
                    dataContentCategory: detailInfoData?.cateNm as string,
                    creatorNm: detailInfoData?.creatorNm as string,
                    brightcoveId: detailInfoData?.videoId as string,
                    mediaContentsType: detailInfoData?.mediaContentType as string,
                  })}
                >
                  <img src="/assets/images/icon/ico-info-script.png" alt="script" />
                </a>
                <div className="detail_info_text">원고 보기</div>
              </li>
              {props.detailInfoData?.cateType !== 'GROUP' && detailInfoData?.downloadableYn !== 'N' && (
                <li>
                  <a
                    href="#"
                    className="detail_info_link"
                    onClick={e => {
                      e.preventDefault();
                      if (detailInfoData?.mediaContentType === 'MAGAZINE') return;
                      history.push(paths.getPath('newsDownload'), props.detailInfoData);
                      setVideoVisible(false);
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    {...getContentsButtonGAProps({
                      buttonType: '다운로드',
                      contentType: detailInfoData?.cateType as string,
                      magazineType:
                        detailInfoData?.contentsDetailType === ContentsDetailType.CARD_COMICS.toString()
                          ? ContentsDetailType.CARD_COMICS.toString()
                          : 'CARD',
                      tags: detailInfoData?.mediaTag as string,
                      title: detailInfoData?.title as string,
                      time: detailInfoData?.videoTotalTime ? detailInfoData.videoTotalTime : -1,
                      dataContentCategory: detailInfoData?.cateNm as string,
                      creatorNm: detailInfoData?.creatorNm as string,
                      brightcoveId: detailInfoData?.videoId as string,
                      mediaContentsType: detailInfoData?.mediaContentType as string,
                    })}
                  >
                    <img src="/assets/images/icon/ico-info-download.png" alt="download" />
                  </a>
                  <div className="detail_info_text">다운로드</div>
                </li>
              )}
            </>
          )}
          {props.detailInfoData?.cateType !== 'GROUP' && (
            <li>
              <a
                className="detail_info_link"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  props.openSharePopup();
                  all_click_datalayer_push(e.currentTarget);
                }}
                {...getContentsButtonGAProps({
                  buttonType: '이메일 공유',
                  contentType: detailInfoData?.cateType as string,
                  magazineType:
                    detailInfoData?.contentsDetailType === ContentsDetailType.CARD_COMICS.toString()
                      ? ContentsDetailType.CARD_COMICS.toString()
                      : 'CARD',
                  tags: detailInfoData?.mediaTag as string,
                  title: detailInfoData?.title as string,
                  time: detailInfoData?.videoTotalTime ? detailInfoData.videoTotalTime : -1, // video total time ? video total time : -1
                  dataContentCategory: detailInfoData?.cateNm as string,
                  creatorNm: detailInfoData?.creatorNm as string,
                  brightcoveId: detailInfoData?.videoId as string,
                  mediaContentsType: detailInfoData?.mediaContentType as string,
                })}
              >
                <img src="/assets/images/icon/ico-info-share.png" alt="share" />
              </a>
              <div className="detail_info_text">이메일 공유</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default DetailInfo;
