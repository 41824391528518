import classNames from 'classnames';
import React from 'react';
import { getHtmlFormatByEscapeHtmlData } from '../../../../../utils/LGLifeUtils';

export interface NoneLoginFAQPopupProps {
  /** 팝업 제목 */
  popupTitle?: string;
  /** 팝업 내용 */
  popupContent: string;
  /** 상단 타이틀 여부 */
  hasHeaderTitle: boolean;
  /** 팝업 종료 시, handler */
  onClose: () => void;
}

/**
 * FAQ - 로그인/회원 가입 시, 자주 묻는 질문 팝업
 * @constructor
 */
const NoneLoginFaqPopup = (props: NoneLoginFAQPopupProps) => {
  return (
    <div className={classNames(['popup qna_popup qna', 'is-active'])}>
      <div className="popup_dim"></div>
      <div className="popup_inner popup_large">
        <div className="popup_header">
          <strong className="popup_title">{props.hasHeaderTitle ? getHtmlFormatByEscapeHtmlData(props.popupTitle as string) : ''}</strong>
        </div>
        <div className="popup_cont">
          <div className="qna_box">
            <div className="qna_box_title">{props.hasHeaderTitle ? '' : getHtmlFormatByEscapeHtmlData(props.popupTitle as string)}</div>
            <div className="qna_box_con custom_scroll">
              <p className="text" dangerouslySetInnerHTML={{ __html: getHtmlFormatByEscapeHtmlData(props.popupContent) || '' }}></p>
            </div>
            <div className="qna_box_btn">
              <button type="button" id="qnaConfirm" onClick={() => props.onClose()}>
                확인
              </button>
            </div>
          </div>
        </div>
        <button type="button" className="popup_close ico_close" onClick={() => props.onClose()}></button>
      </div>
    </div>
  );
}

 export default NoneLoginFaqPopup;