import { endpoints } from 'services';
import request from 'services/request';

export interface EventRequest {
  eventStatus: string;
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: Array<string>;
}

export interface EventDetailReq {}

export interface EventLikeReq {
  eventId: string;
}

export default {
  getEvent: (params: EventRequest): Promise<EventListResDto> =>
    request({
      url: endpoints.event.event,
      method: 'get',
      params,
    }),
  getEventDetail: (url: string, params?: EventDetailReq): Promise<EventDetailResDto> =>
    request({
      url,
      method: 'get',
      params,
    }),
  postEventLike: (params: EventLikeReq): Promise<any> =>
    request({
      url: endpoints.event.like,
      method: 'post',
      params,
    }),
  postEventShare: (params: ShareReqDto): Promise<any> =>
    request({
      url: endpoints.event.share,
      method: 'post',
      data: params,
    }),
  postEventSurvey: (url: string, params: EventSurveyAnswerListReqDto): Promise<any> =>
    request({
      url,
      method: 'post',
      data: params,
    }),
  postEventQuiz: (url: string, params: QuizUserReplyAnswerListReqDto): Promise<any> =>
    request({
      url,
      method: 'post',
      data: params,
    }),
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성이 존재하여 화면에서만 미노출 처리) - 2025.03.10
  // postEventSimpleSurvey: (url: string, params: SimpleSurveyAnswerReqDto): Promise<any> =>
  //   request({
  //     url,
  //     method: 'post',
  //     data: params,
  //   }),
};
