import request from 'services/request';
import { endpoints } from 'services';

export interface FaqRequest {
  cateId?: string;
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: string[];
}

export default {
  /** 로그인 FAQ 목록 조회 */
  getFaq: () =>
    request({
      url: endpoints.faq.faq,
      method: 'get',
    }),
  /** 비 로그인 FAQ 목록 조회 */
  getNoneLoginFaq: (): Promise<FaqNoneLoginListResDto> =>
    request({
      url: endpoints.faq.noneLoginFaq,
      method: 'get',
    }),
};
