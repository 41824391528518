import classNames from 'classnames';
import { TEMP_IMAGE_UPLOAD_API_URL } from 'commons/constants';
import { Editor, FileUploader, Popup, SelectBox } from 'components/atoms';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import { useCommon, useQna } from 'hooks';
import React, { useEffect, useState } from 'react';

interface QnaPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const placeHolderRegistStr = '회원가입 관련 FAQ 참고해주세요(FAQ 내용)';
const placeHolderUpdateMemberStr = '회원정보 수정 관련 FAQ를 참고해주세요(FAQ 내용)';
const placeHolderVideoPlayStr =
  '영상 재생 관련 FAQ 확인부탁드립니다. FAQ에 해당사항이 없을 경우, 영상 재생 환경을 자세히 입력 부탁드립니다. 1.근무지(건물 및 층수까지 입력 부탁드립니다, 재택근무일경우 VPN 사용여부 기재) 2.인터넷 연결 상태(랜선 or 와이파이) 3.클라우드 PC사용 여부(O,X) 4.사용 브라우저 및 버전 정보 5.오류 현상 스크린 샷 6.오류 현상 기술(상세히 기록 부탁드립니디)';
const placeHolderInnerStr = '사내방송에 다뤄졌으면하는 내용을 제보해주세요!(jebo@lgcc.co.kr로도 제보 가능합니다)';
const placeHolderEtcStr = '';

const QnaPopup = (props: QnaPopupProps) => {
  const { category, postQnaNonLoginWrite } = useQna();
  const { domains } = useCommon();

  const [name, setName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [email, setEmail] = useState('');
  const [categoryIndex, setCategoryIndex] = useState<number>(-1);
  const [placeHolder, setPlaceHoder] = useState<string>('');
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<AttachFileResDto[]>([]);
  const [isValidData, setIsValidData] = useState(false);
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [isCancelPopup, setIsCancelPopup] = useState(false);
  const [isRegistPopup, setIsRegistPopup] = useState(false);

  // 이메일 형식 검사 함수
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  /**
   * 국제 전화 번호 검증 여부
   * @param phone
   */
  const isValidInternationalPhoneNum = (phone: string) => {
    // 공백과 하이픈 제거
    const normalizedPhone = phone.replace(/[\s-]/g, '');
    // E.164 규격: 선택적 '+' 기호, 첫 숫자는 1~9, 이후 최소 9자리, 최대 14자리 숫자
    const phoneRegex = /^\+?[1-9]\d{9,14}$/;
    return phoneRegex.test(normalizedPhone);
  };

  /**
   * 휴대 전화 번호 검사
   * - 국제 전화 번호의 경우, (+) 기호 포함 최대 16자리
   * - 국내 전화 번호의 경우, 최대 11자리
   * @param phone
   */
  const isValidPhoneNum = (phone: string) => {
    // 국제 전화 번호 검증
    if (phone.substr(0, 1) === '+') {
      return isValidInternationalPhoneNum(phone);
    }
    // 국내 전화 번호 - 입력 값에서 하이픈을 모두 제거
    const normalizedPhone = phone.replace(/-/g, '');
    const phoneRegex = /^[0-9]{10,11}$/;
    return phoneRegex.test(normalizedPhone);
  };

  useEffect(() => {
    setIsValidData(false);

    if (
      categoryIndex > -1 &&
      title.trim().length > 0 &&
      contents.trim().length > 0 &&
      name.trim().length > 0 &&
      phoneNum.trim().length > 0 &&
      email.trim().length > 0 &&
      isValidPhoneNum(phoneNum)
    ) {
      setIsValidData(true);
    }
  }, [categoryIndex, title, contents, name, phoneNum, email]);

  const onChangeName = e => {
    setName(e.target.value);
  };

  /**
   * 전화 번호 onChange 이벤트
   * @param e
   */
  const onChangePhoneNum = e => {
    // 숫자, 하이픈, + 기호만 허용 (최대 30자)
    const input = e.target.value.trim();
    if (!input) {
      setPhoneNum('');
      return;
    }
    const phoneNumberReg = /^[+\d-]{0,16}$/;
    if (!phoneNumberReg.test(input)) {
      return;
    }
    setPhoneNum(input);
  };

  const onBlurPhoneNumber = (value: string) => {
    if (!value) return;
    setPhoneNum(value.replace(/-/g, ''));
    if (!isValidPhoneNum(value)) {
      setAlertPopContents('올바른 휴대 전화 번호를 입력해주세요.\n ex) 01012345678, +821012345678 (10~16자리)');
      setIsAlertPopupOpen(true);
      return;
    }
  };

  const onChangeEmail = e => {
    setEmail(e.target.value.trim());
  };

  const onBlurEmail = (value: string) => {
    if (!value) return;
    if (!isValidEmail(value)) {
      setAlertPopContents('올바른 이메일 주소를 입력해주세요.');
      setIsAlertPopupOpen(true);
      return;
    }
  };

  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onAddFiles = (addedfiles: File[]) => {
    setFiles(files.concat(addedfiles));
  };

  const onRemoveFile = (i: number, isLocal: boolean) => {
    if (isLocal) {
      const temp = [...files];
      temp.splice(i, 1);
      setFiles(temp);
    } else {
      const temp = [...uploadedFiles];
      temp.splice(i, 1);
      setUploadedFiles(temp);
    }
  };

  // 등록 버튼 클릭 시 실행되는 함수
  const onRegistPopup = () => {
    if (isValidData) {
      // 이메일 유효성 검사
      if (!isValidEmail(email)) {
        setAlertPopContents('올바른 이메일 주소를 입력해주세요.');
        setIsAlertPopupOpen(true);
        return;
      }
      // 휴대 전화 번호 유효성 검사 (숫자 10~16자리)
      if (!isValidPhoneNum(phoneNum)) {
        setAlertPopContents('올바른 휴대 전화 번호를 입력해주세요.\n ex) 01012345678, +821012345678 (10~16자리)');
        setIsAlertPopupOpen(true);
        return;
      }
      setIsRegistPopup(true);
    } else {
      const formItems = [] as string[];
      if (categoryIndex <= -1) formItems.push('문의 유형');
      if (title.trim().length <= 0) formItems.push('제목');
      if (contents.trim().length <= 0) formItems.push('내용');
      if (name.trim().length <= 0) formItems.push('성명');
      if (phoneNum.trim().length <= 0) formItems.push('휴대전화 번호');
      if (email.trim().length <= 0) formItems.push('이메일');
      setAlertPopContents(`${formItems.join(', ')}을(를) 작성했는지 확인해주세요.`);
      setIsAlertPopupOpen(true);
    }
  };

  const onRegist = () => {
    postQnaNonLoginWrite(
      title,
      contents,
      category[categoryIndex]?.cateId as string,
      email,
      name,
      phoneNum,
      files.length > 0 ? files[0] : undefined
    ).then(res => {
      if (res.resultCode === 200) {
        console.log('sucess');
        setAlertPopContents('문의하신 내용이 정상적으로 등록되었습니다.');
        setIsAlertPopupOpen(true);
        setIsRegistPopup(false);
        formClear();
        props.onClose();
      } else {
        setAlertPopContents(res.message);
        setIsAlertPopupOpen(true);
        console.log('error');
      }
    });
  };

  const formClear = () => {
    setName('');
    setEmail('');
    setPhoneNum('');
    setTitle('');
    setContents('');
    setCategoryIndex(-1);
    setFiles([]);
    setPlaceHoder('');
  };

  const setPlaceHolderStr = index => {
    if (index < 0) {
      return;
    }
    setPlaceHoder(
      category[index].guideSentence !== null
        ? String(category[index]?.guideSentence)
            .replace(/&nbsp;/g, ' ')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/&quot;/g, '"')
            .replace(/&#035;/g, '#')
            .replace(/&#039;/g, ';')
        : ''
    );
  };

  return (
    <>
      <div className={classNames('popup qna_login_popup', { 'is-active': props.isOpen })}>
        <div className="popup_dim"></div>
        <div className="popup_inner popup_mobile_full">
          <div className="popup_header">
            <strong className="popup_title">문의하기</strong>
          </div>
          <div className="popup_cont custom_scroll">
            <div className="qna_login_box">
              <div className="account_row is-2line">
                <div className="account_item">
                  <div className="account_title">
                    성명<span className="is-necessary">*</span>
                  </div>
                  <input
                    type="text"
                    className="account_value is-required"
                    title="성명"
                    placeholder="성명을 입력해주세요"
                    onChange={onChangeName}
                    value={name}
                  />
                </div>
                <div className="account_item">
                  <div className="account_title">
                    휴대전화<span className="is-necessary">*</span>
                  </div>
                  <input
                    type="tel"
                    id="phoneNumber"
                    className="account_value is-required"
                    maxLength={16}
                    title="휴대전화"
                    placeholder="휴대전화 번호를 입력해주세요"
                    onChange={onChangePhoneNum}
                    onBlur={e => onBlurPhoneNumber(e.target.value)}
                    value={phoneNum}
                  />
                </div>
              </div>
              <div className="account_row is-email">
                <div className="account_item">
                  <div className="account_title">
                    이메일<span className="is-necessary">*</span>
                  </div>
                  <input
                    type="text"
                    className="account_value is-required"
                    title="이메일"
                    placeholder="이메일 주소"
                    onChange={onChangeEmail}
                    onBlur={e => onBlurEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>

            <div className="qna_login_write">
              <form id="write">
                <div className="wirte_section">
                  <div className="qna_select board_select">
                    <SelectBox
                      className={'qna_select'}
                      initial={'문의 유형을 선택해주세요.'}
                      categoryIndex={categoryIndex}
                      clickSelectBox={index => {
                        setCategoryIndex(index);
                        setPlaceHolderStr(index);
                      }}
                      isRequired={categoryIndex === -1}
                      selectBoxList={
                        category && category?.length ? (category.map(cate => cate.cateNm) as string[]) : []
                      }
                      tagging={'qna'}
                      initialElementId="ask_type_ga"
                    />
                  </div>
                </div>

                <div className="wirte_section">
                  <div className="write_section_input write_content">
                    <div className="write_content_input">
                      {title.length === 0 && <span className="write_required">*</span>}
                      <input
                        type="text"
                        title="제목"
                        className="write_input write_title is-required"
                        placeholder="제목을 입력해주세요."
                        onChange={onChangeTitle}
                        value={title}
                      />
                    </div>
                    <div className="write_content_input">
                      <Editor
                        type="classic"
                        data={contents}
                        height={100}
                        placeHolder={placeHolder}
                        uploadUrl={TEMP_IMAGE_UPLOAD_API_URL}
                        onChange={evt => {
                          if (evt.editor.getData()) {
                            setPlaceHoder('');
                          } else {
                            setPlaceHolderStr(categoryIndex);
                          }
                          setContents(evt.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="wirte_section">
                  <FileUploader
                    onAddFiles={onAddFiles}
                    onRemoveFile={onRemoveFile}
                    maxCount={1}
                    maxMBSize={5}
                    selectedFiles={files}
                    uploadedFiles={uploadedFiles}
                  />
                </div>
                <div className="write_footer">
                  <div className="write_button_section">
                    <button
                      type="button"
                      className="popup_bottom_btn popup_bottom_cancel is-close"
                      onClick={() => setIsCancelPopup(true)}
                    >
                      취소
                    </button>
                    <button
                      type="button"
                      className={classNames(['popup_bottom_btn popup_bottom_confirm', { 'is-active': isValidData }])}
                      onClick={onRegistPopup}
                    >
                      등록
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <button type="button" className="qna_login_close ico_close" onClick={() => setIsCancelPopup(true)}></button>
        </div>
      </div>
      <Popup
        className={'cancel_write_popup'}
        okString={'확인'}
        contents={'게시글 작성을 취소하시겠습니까?<br />취소시 작성중이던 게시글 내용은 모두 삭제됩니다.'}
        isOpen={isCancelPopup}
        onCancel={() => setIsCancelPopup(false)}
        onRegist={() => {
          setIsCancelPopup(false);
          formClear();
          props.onClose();
        }}
      />
      <Popup
        isOpen={isRegistPopup}
        className={'register_write_popup'}
        okString={'등록'}
        contents={'작성한 게시글 등록 하시겠습니까?'}
        onCancel={() => setIsRegistPopup(false)}
        onRegist={() => {
          setIsRegistPopup(false);
          onRegist();
        }}
      />
      <AlertPopup isOpen={isAlertPopupOpen} hasNewlineAlertPopup={true} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default QnaPopup;
