import { fetcher } from 'services';

export interface MediaSimpleSurveyItemType {
  percentage: number;
  votes: number | undefined;
  id: number;
  text: string | undefined;
  key: string | undefined;
}

const useMediaSimpleSurveyDetail = () => {
  return {
    callAddMediaSimpleSurveyAnswer(params: MediaSimpleSurveyAnswerReqDto) {
      return fetcher.media.addMediaSimpleSurveyAnswer(params);
    },
  };
};

export default useMediaSimpleSurveyDetail;
