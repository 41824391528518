import React from 'react';
import { Link } from 'react-router-dom';
import { all_click_datalayer_push, getRelatedContentsGAProps } from 'utils/GAUtils';
import { dateYYYYMMDD, imageUrl, videoTimeFormat } from 'utils/utils';
import paths from '../../../commons/paths';
import { ContentsDetailType } from '../../../types/enum/ContentsDetailType';

interface RelationContentsProps {
  related: MediaPlaylistResDto[];
  cateType: 'VIDEO' | 'NEWS' | 'GROUP' | 'CARD' | undefined;
}

const RelationContents = (props: RelationContentsProps) => {
  const getPathFromMediaCardResDto = (mediaData: MediaCardResDto) => {
    if (!mediaData.contentsType)
      return `${paths.getPath('videoDetail').replace(':mediaId', mediaData.mediaId as string)}`;
    const contentsType = mediaData.contentsType.toLowerCase().toString();

    if (contentsType === 'news')
      return `${paths.getPath('newsDetail').replace(':mediaId', mediaData.mediaId as string)}`;
    if (contentsType === 'card') {
      // 갓찌 웹툰
      if (mediaData.contentsDetailType === ContentsDetailType.CARD_COMICS.toString()) {
        return `${paths.getPath('webtoonDetail').replace(':mediaId', mediaData.mediaId as string)}`;
      }
      // 그 외 - 매거진(글/카드 뉴스)
      return `${paths.getPath('cardNewsDetail').replace(':mediaId', mediaData.mediaId as string)}`;
    }
    return `${paths.getPath('videoDetail').replace(':mediaId', mediaData.mediaId as string)}`;
  };

  return (
    <>
      <div className="detail_right_title">연관 콘텐츠</div>
      <ul className="detail_relation">
        {props.related.map((contents: MediaPlaylistResDto, idx) => (
          <li key={idx}>
            <Link
              to={getPathFromMediaCardResDto(contents)}
              className="detail_relation_link"
              {...getRelatedContentsGAProps({
                area: '콘텐츠추가탐색',
                contentsType: props.cateType,
                title: contents.title,
                time: contents.videoTotalTime,
                isVideo: Boolean(contents.videoTotalTime),
                category: contents.cateNm,
                belong: '연관콘텐츠',
                maker: contents.creatorNm,
                tags: contents.mediaTag?.slice(1).replace(/#/g, '_'),
                brightcoveId: contents.videoId,
                mediaContentsType: contents.mediaContentsType as string,
              })}
              onClick={e => {
                window.scrollTo(0, 0);
                all_click_datalayer_push(e.currentTarget);
              }}
            >
              <div className="detail_relation_wrap">
                <div className="relation_thumb">
                  <img
                    src={imageUrl(contents.thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
                    alt=""
                    className="relation_thumb_img"
                  />
                  {contents.mediaContentsType === 'VIDEO' && (
                    <span className="card_thumb_time">{videoTimeFormat(contents.videoTotalTime)}</span>
                  )}
                </div>
                <div className="relation_desc">
                  <div className="relation_title">{contents.title}</div>
                  <div className="relation_sub is-pc">
                    {props.cateType !== 'GROUP' && (
                      <ul className="card_record">
                        <li>
                          <img src="/assets/images/icon/ico-views.png" alt="views" />
                          {contents.readCnt}
                        </li>
                        <li>
                          <img src="/assets/images/icon/ico-comments.png" alt="comments" />
                          {contents.commentCnt}
                        </li>
                        <li>
                          <img src="/assets/images/icon/ico-likes.png" alt="likes" />
                          {contents.likeCnt}
                        </li>
                      </ul>
                    )}
                    <div className="relation_sub_date">{dateYYYYMMDD(contents.postDt)}</div>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default React.memo(RelationContents, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
